import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  padding1: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '1rem',
    }
  },
  border1: {
    border: '1px solid blue',
  },
  image: {
    width: '100%',
    // borderRadius: '5px'
  },
  
}));

const SearchResult = ({item}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} sm={4} >
        <img className={classes.image} src={item.churchPhoto} alt='church' />

      </Grid>
      <Grid item xs={12} sm={8} className={classes.padding1}>
        <Typography variant="body1"><strong>Name:</strong> {item.churchName}</Typography>
        <Typography variant="body1" gutterBottom><strong>Location:</strong> {item.city},  {item.state}</Typography>
      </Grid>
    </>
  )
}

export default SearchResult;