const states = [
      {short: "AK", long: "Alaska"},
      {short: "AZ", long: "Arizona"},
      {short: "AR", long: "Arkansas"},
      {short: "CA", long: "California"},
      {short: "CO", long: "Colorado"},
      {short: "CT", long: "Connecticut"},
      {short: "DE", long: "Delaware"},
      {short: "DC", long: "District Of Columbia"},
      {short: "FL", long: "Florida"},
      {short: "GA", long: "Georgia"},
      {short: "HI", long: "Hawaii"},
      {short: "ID", long: "Idaho"},
      {short: "IL", long: "Illinois"},
      {short: "IN", long: "Indiana"},
      {short: "IA", long: "Iowa"},
      {short: "KS", long: "Kansas"},
      {short: "KY", long: "Kentucky"},
      {short: "LA", long: "Louisiana"},
      {short: "ME", long: "Maine"},
      {short: "MD", long: "Maryland"},
      {short: "MA", long: "Massachusetts"},
      {short: "MI", long: "Michigan"},
      {short: "MN", long: "Minnesota"},
      {short: "MS", long: "Mississippi"},
      {short: "MO", long: "Missouri"},
      {short: "MT", long: "Montana"},
      {short: "NE", long: "Nebraska"},
      {short: "NV", long: "Nevada"},
      {short: "NH", long: "New Hampshire"},
      {short: "NJ", long: "New Jersey"},
      {short: "NM", long: "New Mexico"},
      {short: "NY", long: "New York"},
      {short: "NC", long: "North Carolina"},
      {short: "ND", long: "North Dakota"},
      {short: "OH", long: "Ohio"},
      {short: "OK", long: "Oklahoma"},
      {short: "OR", long: "Oregon"},
      {short: "PA", long: "Pennsylvania"},
      {short: "RI", long: "Rhode Island"},
      {short: "SC", long: "South Carolina"},
      {short: "SD", long: "South Dakota"},
      {short: "TN", long: "Tennessee"},
      {short: "TX", long: "Texas"},
      {short: "UT", long: "Utah"},
      {short: "VT", long: "Vermont"},
      {short: "VA", long: "Virginia"},
      {short: "WA", long: "Washington"},
      {short: "WV", long: "West Virginia"},
      {short: "WI", long: "Wisconsin"},
      {short: "WY", long: "Wyoming"}
];

export default states


// const States = ({id, className, handleChange, selectedValue}) => {
//   return(
//     <select id={id} className={className} onChange={handleChange} value={selectedValue}>
//       <option value=""; "</option>
//       <option value="AL">Alabama</option>
//       <option value="AK">Alaska</option>
//       <option value="AZ">Arizona</option>
//       <option value="AR">Arkansas</option>
//       <option value="CA">California</option>
//       <option value="CO">Colorado</option>
//       <option value="CT">Connecticut</option>
//       <option value="DE">Delaware</option>
//       <option value="DC">District Of Columbia</option>
//       <option value="FL">Florida</option>
//       <option value="GA">Georgia</option>
//       <option value="HI">Hawaii</option>
//       <option value="ID">Idaho</option>
//       <option value="IL">Illinois</option>
//       <option value="IN">Indiana</option>
//       <option value="IA">Iowa</option>
//       <option value="KS">Kansas</option>
//       <option value="KY">Kentucky</option>
//       <option value="LA">Louisiana</option>
//       <option value="ME">Maine</option>
//       <option value="MD">Maryland</option>
//       <option value="MA">Massachusetts</option>
//       <option value="MI">Michigan</option>
//       <option value="MN">Minnesota</option>
//       <option value="MS">Mississippi</option>
//       <option value="MO">Missouri</option>
//       <option value="MT">Montana</option>
//       <option value="NE">Nebraska</option>
//       <option value="NV">Nevada</option>
//       <option value="NH">New Hampshire</option>
//       <option value="NJ">New Jersey</option>
//       <option value="NM">New Mexico</option>
//       <option value="NY">New York</option>
//       <option value="NC">North Carolina</option>
//       <option value="ND">North Dakota</option>
//       <option value="OH">Ohio</option>
//       <option value="OK">Oklahoma</option>
//       <option value="OR">Oregon</option>
//       <option value="PA">Pennsylvania</option>
//       <option value="RI">Rhode Island</option>
//       <option value="SC">South Carolina</option>
//       <option value="SD">South Dakota</option>
//       <option value="TN">Tennessee</option>
//       <option value="TX">Texas</option>
//       <option value="UT">Utah</option>
//       <option value="VT">Vermont</option>
//       <option value="VA">Virginia</option>
//       <option value="WA">Washington</option>
//       <option value="WV">West Virginia</option>
//       <option value="WI">Wisconsin</option>
//       <option value="WY">Wyoming</option>
//     </select>				
// 	);
// }

// export default States