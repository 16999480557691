import React from 'react';
import * as ROUTES from '../../constants/routes';
import { Switch, Route } from 'react-router-dom';
import TopNavigation from './TopNavigation'
import RVerNavigation from './RVerNavigation'
import HostNavigation from './HostNavigation'

const Navigation = () => {
  return (
    <Switch>
      <Route path={`${ROUTES.HOST}`}>
        <HostNavigation />
      </Route>
      <Route path={`${ROUTES.RVER}`}>
        <RVerNavigation />
      </Route>
      <Route path={`${ROUTES.INDEX}`}>
        <TopNavigation />
      </Route>
    </Switch>
  );
  
}

export default Navigation;