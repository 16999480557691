import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  main: {
    padding: '2rem',
  }  
}));

const Legal = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.main} container spacing={3} justify="center">

      <Grid item xs={12}>
        <Typography variant='h3' color="textPrimary" align="center" gutterBottom>Our lawyer made us do this*</Typography>
      </Grid> 

      <Grid item xs={false} sm={2}></Grid>
      <Grid item xs={12} sm={8} >
        <Typography variant='body1' color="textPrimary" align="left">
        <ul>
          <li>
            All RV members and hosts agree to abide by <Link to="/commandments">The Holy Hosts Ten Commandments</Link>. RVers also agree to follow the rules given to them by their host. If you don’t, it’s just mean. It also gives us grounds to terminate your membership without a refund. Please don’t make us do that.
          </li>
          <li>
            You can’t share The Holy Hosts™ membership contact list with anyone. No, seriously. You paid for the information and it is for your personal use, only. If someone who didn’t pay for the list uses it, that’s called stealing. And that’s bad. Don’t help people steal.
          </li>
          <li>
            Members acknowledge that they are insured pursuant to state laws and agree to take responsibility for their actions and any resulting consequences. Proof of such insurance must be carried in the vehicle. The host locations have no responsibility for damage or theft to your RV, tow car or personal property, or for injury to you, your family or guests.
          </li>
          <li>
            Members agree to hold harmless The Holy Hosts™ all hosts and their officers, directors, employees and agents from and against any loss, expense, liability, damage, claim (including reasonable attorneys’ fees) made or brought by or on behalf of you, your family or guests, or brought against The Holy Hosts™ as a result of your actions, for any and all claims, controversies, actions, causes of actions, liabilities, demands or damages of whatever name or nature that arise from your use of any The Holy Hosts™ materials or information.
          </li>
        </ul>
        </Typography>
      
        <Typography variant='h6' color="textPrimary" align="left">*Thank-you, Peter.</Typography>
      </Grid>
      
      <Grid item xs={false} sm={2}></Grid>

    </Grid>
  );
}

export default Legal;