import React from 'react';
import './hostProfile.css'; 
import states from '../../../util/States';
import {  Button, 
          Typography, 
          TextField, 
          Select, 
          MenuItem, 
          FormControl, 
          InputLabel,
          Paper} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
    marginBottom: '2rem',
  },
  inputLabel: {
    color: '#000981'
  },
  formControl: {
    minWidth: 120,
    color: '#000981'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  fileInput: {
    marginBottom: '.5rem',
  },
  imageError: {
    padding: '1rem',
    backgroundColor: 'rgb(190, 48, 48)',
    color: '#ffffff',
    borderRadius: '5px',
    margin: '.5rem 0 .5rem 0'
  
  },
  photoContainer: {
    padding: '2rem'
  }
}));

const labelStyle = {
  style: {
    color: '#000981'
  } 
}

const ImageErrorContainer = ({visible}) => {
  const classes = useStyles();
 
  if (visible) {
    return (
      <Typography className={classes.imageError} component="div">
          Your selected image is too big to upload. <br></br>Please select an image smaller than 2 megabytes.
        </Typography>
    )
  } else {
    return null;
  }

}

const HostDataEdit = ({hostData, doOnChange, doSubmit, toggleEdit, doFileSelect, errorFields}) => {
  const classes = useStyles();
  
  return (
    <div className="host-edit-container">
      <Typography variant="h5" gutterBottom>Required info for our files:</Typography>
<br />
      <TextField className={classes.textField} color="primary" label="Name of your organization/church" 
        id="churchName" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.churchName ? hostData.churchName : ''} 
        inputProps={{ maxLength: 100 }}
      />
      
      <TextField className={classes.textField} color="primary" label="Your name" 
        id="signupName" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.signupName ? hostData.signupName : ''} 
        inputProps={{ maxLength: 100 }}
      />

      <TextField className={classes.textField} color="primary" label="Your title" 
        id="signupTitle" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.signupTitle ? hostData.signupTitle : ''} 
        inputProps={{ maxLength: 100 }}
      />

      <TextField className={classes.textField} color="primary" label="Your phone" 
        id="signupPhone" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.signupPhone ? hostData.signupPhone : ''} 
        inputProps={{ maxLength: 20 }}
      />
      
      <TextField className={classes.textField} color="primary" label="Your email" 
        id="signupEmail" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.signupEmail ? hostData.signupEmail : ''} 
        inputProps={{ maxLength: 100 }}
      />

      <br />
      <br />

      <Typography variant="h5" gutterBottom>Required info for your listing on The Holy Hosts website:</Typography>
      
      <TextField className={classes.textField} color="primary" label="Name of your organization/church" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.churchName ? hostData.churchName : ''} 
        disabled
        inputProps={{ maxLength: 100 }}
      />

      <TextField className={classes.textField} color="primary" label="Address 1" 
        id="address1" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.address1 ? hostData.address1 : ''} 
        inputProps={{ maxLength: 200 }}
      />

      <TextField className={classes.textField} color="primary" label="Address 2" 
        id="address2" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.address2 ? hostData.address2 : ''} 
        inputProps={{ maxLength: 200 }}
      />

      <TextField className={classes.textField} color="primary" label="City" 
        id="city" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.city ? hostData.city : ''} 
        inputProps={{ maxLength: 200 }}
      />
      

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="state-select-label" className={classes.inputLabel}>State</InputLabel>
        <Select
          labelId="state-select-label"
          id="state"
          name="state"
          value={hostData.state ? hostData.state : ''}
          className={classes.selectEmpty}
          onChange={doOnChange}
          displayEmpty
        >
          <MenuItem value=" ">&nbsp; </MenuItem>
          {
            states.map((st, index) => {
              return <MenuItem key={index} value={st.short}>{st.short}</MenuItem>;
            })
          }
        </Select>
        <br /><br />
      </FormControl>
      
      <TextField className={classes.textField} color="primary" label="Zipcode" 
        id="zipcode" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.zipcode ? hostData.zipcode : ''} 
        inputProps={{ maxLength: 20 }}
      />

      <TextField className={classes.textField} color="primary" label="Website" 
        id="website" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.website ? hostData.website : ''} 
        inputProps={{ maxLength: 100 }}
      />

      <div className="host-edit-item">
      <Typography variant="body1" gutterBottom component="div">
        <p>Let us know how you would you like guests to make reservations? 
        (At least one of the following is required.)</p> 
      </Typography>
      
      <TextField className={classes.textField} color="primary" label="Call (phone number)" 
        id="callPhone" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.callPhone ? hostData.callPhone : ''} 
        inputProps={{ maxLength: 20 }}
      />
      
      <TextField className={classes.textField} color="primary" label="Text (phone number)" 
        id="textPhone" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.textPhone ? hostData.textPhone : ''} 
        inputProps={{ maxLength: 20 }}
      />
      
      <TextField className={classes.textField} color="primary" label="Email" 
        id="email" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.email ? hostData.email : ''} 
        inputProps={{ maxLength: 100 }}
      />

      <br />
      <Typography variant="h5" gutterBottom>Additional info for your listing – fill in all that apply</Typography>

      
      <br />
      <TextField className={classes.textField} color="primary" label="Name of the person in your organization who will be taking reservations" 
        id="contactPerson" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.contactPerson ? hostData.contactPerson : ''} 
        inputProps={{ maxLength: 100 }}
      />

      <TextField className={classes.textField} color="primary" label="How far in advance do guests need to make a reservation?" 
        id="reservationLeadTime" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.reservationLeadTime ? hostData.reservationLeadTime : ''} 
        inputProps={{ maxLength: 100 }}
      />

      <TextField className={classes.textField} color="primary" label="What nights of the week is your parking lot regularly available?" 
        id="nightsAvailable" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.nightsAvailable ? hostData.nightsAvailable : ''} 
        inputProps={{ maxLength: 200 }}
      />

      <TextField className={classes.textField} color="primary" label="List any special dates when you are not regularly available to guests" 
        id="blackoutDates" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.blackoutDates ? hostData.blackoutDates : ''} 
        inputProps={{ maxLength: 200 }}
      />  

      <TextField className={classes.textField} color="primary" label="What is the link for making an online donation?" 
        id="donationLink" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.donationLink ? hostData.donationLink : ''} 
        inputProps={{ maxLength: 100 }}
      />  
      
      <TextField className={classes.textField} color="primary" label="If no online donation option, how should guests leave a donation for their stay?" 
        id="altDonationInstructions" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        multiline
        rowsMax={2}
        onChange={doOnChange}
        value={hostData.altDonationInstructions ? hostData.altDonationInstructions : ''} 
        inputProps={{ maxLength: 1000 }}
      />  

<     TextField className={classes.textField} color="primary" label="List any quiet hours" 
        id="quietHours" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.quietHours ? hostData.quietHours : ''} 
        inputProps={{ maxLength: 500 }}
      />  

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="state-select-label" className={classes.inputLabel}>Are pets allowed?</InputLabel>
        <Select
          labelId="state-select-label"
          id="pets"
          name="pets"
          value={hostData.pets !== undefined ? hostData.pets : ''}
          className={classes.selectEmpty}
          onChange={doOnChange}
          displayEmpty
        >
         <MenuItem value=""></MenuItem>;
         <MenuItem value={true}>Yes</MenuItem>;
         <MenuItem value={false}>No</MenuItem>;
          
        </Select>
        <br /><br />
      </FormControl>
    
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="state-select-label" className={classes.inputLabel}>Are children allowed?</InputLabel>
        <Select
          labelId="state-select-label"
          id="children"
          name="children"
          value={hostData.children !== undefined ? hostData.children : ''}
          className={classes.selectEmpty}
          onChange={doOnChange}
          displayEmpty
        >
         <MenuItem value=""></MenuItem>;
         <MenuItem value={true}>Yes</MenuItem>;
         <MenuItem value={false}>No</MenuItem>;
          
        </Select>
        <br /><br />
      </FormControl>
   
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="state-select-label" className={classes.inputLabel}>Electricity available?</InputLabel>
        <Select
          labelId="state-select-label"
          id="electricity"
          name="electricity"
          value={hostData.electricity !== undefined ? hostData.electricity : ''}
          className={classes.selectEmpty}
          onChange={doOnChange}
          displayEmpty
        >
         <MenuItem value=""></MenuItem>;
         <MenuItem value={true}>Yes</MenuItem>;
         <MenuItem value={false}>No</MenuItem>;
          
        </Select>
        <br /><br />
      </FormControl>
    
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="state-select-label" className={classes.inputLabel}>Water hookup available?</InputLabel>
        <Select
          labelId="state-select-label"
          id="water"
          name="water"
          value={hostData.water !== undefined ? hostData.water : ''}
          className={classes.selectEmpty}
          onChange={doOnChange}
          displayEmpty
        >
         <MenuItem value=""></MenuItem>;
         <MenuItem value={true}>Yes</MenuItem>;
         <MenuItem value={false}>No</MenuItem>;
          
        </Select>
        <br /><br />
      </FormControl>
      
      <br />
      <br />
      <TextField className={classes.textField} color="primary" label="Maximum allowed RV length (feet)" 
        id="rvMaxLength" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.rvMaxLength ? hostData.rvMaxLength : ''}
        type="number" 
      />

      <TextField className={classes.textField} color="primary" label="Maximum allowed RV width (feet)" 
        id="rvMaxWidth" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.rvMaxWidth ? hostData.rvMaxWidth : ''}
        type="number" 
      />
    
      <TextField className={classes.textField} color="primary" label="Maximum allowed RV height (feet)" 
        id="rvMaxHeight" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.rvMaxHeight ? hostData.rvMaxHeight : ''}
        type="number" 
      />
    
      <TextField className={classes.textField} color="primary" label="Maximum length of stay (nights) " 
        id="maxStay" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.maxStay ? hostData.maxStay : ''}
        type="number" 
      />
    
    <br/>
    
      <TextField className={classes.textField} color="primary" label="What makes your location special?" 
        id="specialNotes" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        multiline
        rowsMax={5}
        value={hostData.specialNotes ? hostData.specialNotes : ''} 
        inputProps={{ maxLength: 2000 }}
      />
  
      <TextField className={classes.textField} color="primary" label="Minimum recommended donation ($)" 
        id="minDonation" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.minDonation ? hostData.minDonation : ''} 
        type="number"
      />
    
      <TextField className={classes.textField} color="primary" label="List any fee-based services you offer (or blank, if none)" 
        id="otherServices" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        multiline
        rowsMax={4}
        value={hostData.otherServices ? hostData.otherServices : ''} 
        inputProps={{ maxLength: 2000 }}
      />
    
      <TextField className={classes.textField} color="primary" label="List any volunteer opportunities you offer (or blank, if none)" 
        id="volunteerOpportunities" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        multiline
        rowsMax={4}
        value={hostData.volunteerOpportunities ? hostData.volunteerOpportunities : ''} 
        inputProps={{ maxLength: 2000 }}
      />
    
    <Typography variant="body1" gutterBottom>
      We ask our RV guests not to have cookouts, campfires or put chairs out while they are visiting you, unless you specifically say they can.  
    </Typography>
    <br />
    <TextField className={classes.textField} color="primary" label="List any other rules you’d like your guests to follow" 
        id="guestRules" 
        InputLabelProps={labelStyle}
        variant="outlined" 
        onChange={doOnChange}
        value={hostData.guestRules ? hostData.guestRules : ''} 
        multiline
        rowsMax={5}
        inputProps={{ maxLength: 2000 }}
      />

      <Paper className={classes.photoContainer}>

        <Typography variant="h5" gutterBottom>Site photos</Typography>
        <Typography variant="h6" gutterBottom>We accept jpg, png, and gif files. 
        </Typography>
        
        <Typography variant="body1" >Maximum file size is 2 megabytes. (We want search to be as fast as possible for your guests!) </Typography>
        <br />
        <Typography variant="body1" gutterBottom>
          If you're having trouble saving a photo, please check to make sure your image isn’t in heic or some other unexpected format.
        </Typography>
        <br />
        <Typography variant="body2" gutterBottom>Image 1</Typography>
        <input  id="hostImage1" className={classes.fileInput}   
          type="file" 
          accept="image/x-png,image/jpeg,image/gif"
          onChange={doFileSelect}
        />
        <ImageErrorContainer visible={errorFields.includes('hostImage1')}/>
          {hostData.hostImage1 ? 
            <div className="host-edit-item-input" >
              <img className="host-edit-church-photo" alt="church" src={hostData.hostImage1}/>
            </div>
            : null
          }
        <br /> 
        <Typography variant="body2" gutterBottom>Image 2</Typography>
        <input  id="hostImage2" className={classes.fileInput} 
          type="file" 
          accept="image/x-png,image/jpeg,image/gif"
          onChange={doFileSelect}
        />
        <ImageErrorContainer visible={errorFields.includes('hostImage2')}/>
          {hostData.hostImage2 ? 
            <div className="host-edit-item-input" >
              <img className="host-edit-church-photo" alt="church" src={hostData.hostImage2}/>
            </div>
            : null
          }
          <br />  
        <Typography variant="body2" gutterBottom>Image 3</Typography>
        <input  id="hostImage3" className={classes.fileInput} 
          type="file" 
          accept="image/x-png,image/jpeg,image/gif"
          onChange={doFileSelect}
        />
        <ImageErrorContainer visible={errorFields.includes('hostImage3')}/>
          {hostData.hostImage3 ? 
            <div className="host-edit-item-input" >
              <img className="host-edit-church-photo" alt="church" src={hostData.hostImage3}/>
            </div>
            : null
          }
      </Paper>

    <br />
    <br />
      <Button variant="contained" color="secondary" className="host-btn spacer-right" onClick={doSubmit}>Save</Button>
      &nbsp;&nbsp;
      <Button variant="contained" color="secondary" className="host-btn" onClick={toggleEdit}>Cancel</Button>
                
   </div>
    </div>    
  );
}

export default HostDataEdit;