import React, { useContext } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { UserContext } from '../../components/Firebase/UserContext'
import './host.css';

import HostLanding from './HostLanding';
import HostProfile from './HostProfile';
import HostSignIn from './HostSignIn';
import HostSignUp from './HostSignUp';
import EmailVerification from '../User/EmailVerification';
import HostLoading from '../Loading/HostLoading';

const Host = () => {
  let {user, loading} = useContext(UserContext);
    
  let { path } = useRouteMatch();
  // if (user && !user.emailVerified) {
  //   return (
  //     <EmailVerification />
  //   );
  // }

  if (loading) {
    return (
      <HostLoading />
    );
  }

  return (
    <div className="main-container">
      <main>
          <Switch>
            <Route path={`${path}${ROUTES.SIGN_UP}`}>
              <HostSignUp />
            </Route>
            <Route path={`${path}${ROUTES.SIGN_IN}`}>
              <HostSignIn />
            </Route>
            <Route path={`${path}${ROUTES.PROFILE}`}>
              <HostProfile />
            </Route>
            <Route path={`${path}${ROUTES.INDEX}`}>
              <HostLanding />
            </Route>
        </Switch>    
      </main>
    </div>
  );
  
}

export default Host;