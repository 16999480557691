import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import './hostLanding.css';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as ROUTES from '../../../constants/routes';

const useStyles = makeStyles((theme) => ({
  bgImage1: {
    borderRadius: '5px',
    height: '100%',
    backgroundPosition: 'center center', 
    backgroundSize: 'cover  ', 
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '2rem',
    backgroundImage: `url('/images/rv_on_road_v_med.jpg')`,
    [theme.breakpoints.down('sm')]: {
      height: '300px',
      backgroundSize: 'contain'
    }

  },
  buttonPadding: {
    marginRight: '1rem'
  },
  textPadding: {
    padding: '2rem 0 2rem 2rem',
  },
  roadBackground: {
    backgroundImage: `linear-gradient(
      rgba(255, 255, 255, 0.98),
      rgba(255, 255, 255, 0.8)
    ),url('/images/HH_CurvyRoad1.png')`,
   
    backgroundRepeat: 'no-repeat',
  },
  
}));

const openStarterPdf = () => {
  window.open('/images/TheHolyHosts_Intro_Kit_4_27.pdf', 'target=starterKit');  
}

const HostLanding = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div id="homeRoadContainer" className={classes.roadBackground}>
    <Grid container spacing={3} justify="center" direction="row" className={classes.textPadding}>
 
      <Grid item xs={12} md={8}>
        <Typography variant='h3' color="textPrimary" align="left" gutterBottom>Welcome, do-gooders!</Typography>
        <Typography variant='body1' color="textPrimary" align="left" gutterBottom>
          Showing <strong>hospitality to travelers and welcoming strangers</strong> is an ancient practice, shared by most of the world’s religions and do-gooders everywhere. 
              Today 40 million people travel across the U.S. by RV each year, and that number is increasing as more and more folks look for safer ways to travel. 
              With so many RVs on the road, some nights it can be tough to find a place to stay. 
              <br />
              <br />
              You can help!
              <br />
              <br />
              Becoming a Holy Host is a great way to:
        </Typography>
        <Typography variant='body1' component="div" color="textPrimary" align="left" >      
          <ul>
            <li>
              Make new friends with people from across the country (and the world)
            </li>
            <li>
              Enliven your members with a new opportunity to serve others
            </li>
            <li>
              Put your resources (ex. your parking lot) to work in service of others
            </li>
            <li>
              Set an example of love and generosity in your community
            </li>
           
          </ul>
          <br />
        </Typography>
        <Typography variant='body1' color="textPrimary" align="left" gutterBottom>
         <strong>Sign up to be a Holy Host today!</strong>  
        </Typography>
        <Typography variant='body1' component="div" color="textPrimary" align="left" gutterBottom>  
        As a host, you will be able to: 
          <ul data-rte-list="default">
            <li>
              <strong>Decide how many RV’s you can host</strong> at one time (we recommend a max of 3) 
            </li>
            <li>
            <strong>Set the dates</strong> and days of the week when you can (and cannot) host  
            </li>
            <li>
              <strong>Take reservations yourself</strong> by phone, email or text directly from your guests so you know exactly who’s coming and when to expect them 
            </li>
            <li>
              <strong>Suggest a minimum donation</strong> for your guests 
            </li>
            <li>
            <strong>Make your own rules</strong> (e.g. Quiet hours, pets or no pets, etc.) for your site 
            </li>
          </ul>
        </Typography>
        <Typography variant='body1' color="textPrimary" align="left" gutterBottom>  
          There is <strong>no cost</strong> to become a Holy Host. We just ask that you follow <Link to="/commandments">The Holy Hosts 10 Commandments.</Link>
          <br />
          <br />
          Click below to get started! 
        </Typography>
        <br />
        <Button variant="contained" className={classes.buttonPadding} color="secondary" onClick={() => history.push(ROUTES.HOST + ROUTES.SIGN_UP)}>Become a host</Button>
        <Button variant="contained" className={classes.buttonPadding} color="secondary" onClick={() => openStarterPdf()}>Get our starter kit</Button>
        </Grid> 

      <Grid item xs={12} md={4}>
      <div className={classes.bgImage1  }></div>
      </Grid> 
    </Grid>
    </div>
    
  );
}

export default HostLanding;