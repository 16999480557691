import React, {useState, useContext} from 'react';
import { geocodeAddress } from '../../../util/geocode'
import { FirebaseContext } from '../../Firebase'
import { useHistory, Route, Switch, useRouteMatch } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import SearchForm from "./SearchForm";
import SearchResultsList from "./SearchResultsList";
import SearchResultDetail from "./SearchResultDetail";

const Search = () => {
  const firebase = useContext(FirebaseContext);
  const firestore = firebase.firestore();
  const history = useHistory();
  //const radiusInM = 50 * 1000; 
  let [searchResults, setSearchResults] = useState([]);
  let [selectedItem, setSelectedItem] = useState(null);
  let [searchTerm, setSearchTerm] = useState({});
  let { path } = useRouteMatch();


  
  const processSearchTerm = async (term) => {
    try {
      let location = await geocodeAddress(term);
      console.log('location');
      console.log(location);
      let latLong = [location.lat(), location.lng()];
      return latLong;
    } 
    catch (error) {
      console.log(`Error returned from geocode call for search term ${term}`);
      console.log(`Error: ${error}`);
    }
  }
  
  const doSearch = async (latLong) => {
    const promises = [];
  
    // Collect all the query results together into a single list
    return Promise.all(promises)
      .then((snapshots) => {
        const matchingDocs = [];
        const results = [];
        console.log('snapshot count = ' + snapshots.length);

       
    
        for (let i=0; i < matchingDocs.length; i++) {
          let data = matchingDocs[i].data();
          results.push(data);
        }

        console.log(results);
         return results;
      });
  }
  
  const handleSearchClick = async () => {
    // console.log('handleSearchClick');
    //let term = document.getElementById('search-term').value;
    // let latLong = await processSearchTerm(term);
    // console.log(latLong);

    // let latLong = await processSearchTerm(term);

    // let results = await doSearch(latLong);

    let results = [{
      churchName: "Our Lady of Bovine Wonderment",
      address1: "234 belinder ave",
      address2: "", 
      city: "Westwood", 
      state: "KS", 
      zipcode: "66205", 
      children: "Yes",
      churchPhoto: "/images/RV_church1.jpg",
      contactPerson: "Silba Mooseman",
      electricity: "Yes",
      email: "jtsharman@gmail.com",
      maxStay: "1",
      minDonation: "1000",
      otherServices: "radioactive hookers",
      pets: "Yes",
      phone: "9131234567",
      rvMaxHeight: "23",
      rvMaxLength: "25",
      rvMaxWidth: "24",
      specialNotes: "We have air to breathe. For breathing. And we're within walking distance of a nuclear reactor! Think how much you'll enjoy that! Cherfuckingnobyl, baby!",
      volunteerOpportunities: "pig washer. you'd be amazed how dirty pigs can get. and you don't want those nasty things running around your nice clean RV making everything smell like dirty wet bacon!",
      water: "Yes"
    },
    {
      churchName: "Third Church of the Disillusioned Aardvark",
      address1: "123 main st", 
      address2: "", 
      city: "Kansas City",
      state: "MO", 
      zipcode: "64108",
      children: "No",
      churchPhoto: "/images/RV_church_3.jpg",
      contactPerson: "Bilb Swww",
      electricity: "No",
      email: "borp@home.com",
      maxStay: "5",
      minDonation: "99.99",
      otherServices: "",
      pets: "No",
      phone: "8161112222",
      rvMaxHeight: "4",
      rvMaxLength: "2",
      rvMaxWidth: "3",
      specialNotes: "",
      volunteerOpportunities: "",
      water: "No"
    },
    {
      churchName: "Fellowship of the Evidentially Dubious",
      address1: "987 main st", 
      address2: "", 
      city: "Kansas City",
      state: "KS", 
      zipcode: "66012",
      children: "No",
      churchPhoto: "/images/RV_church_2a.jpg",
      contactPerson: "Gondo Yipsid",
      electricity: "No",
      email: "forp@foted.com",
      maxStay: "15",
      minDonation: "42000",
      otherServices: "",
      pets: "Yes",
      phone: "8161112345",
      rvMaxHeight: "40",
      rvMaxLength: "200",
      rvMaxWidth: "30",
      specialNotes: "",
      volunteerOpportunities: "wash everyone's stuff",
      water: "Yes"
    }];

    // setSearchResults(results);
    // setSearchTerm({
    //   term: '64108',
    //   location: {latitude: 39.182903, longitude: -94.613991}
    // });

    // history.push("/rver/search/results");
  }

  const handleItemSelect = (key) => {
    let itemSelected = searchResults[key];
    setSelectedItem(itemSelected);

    history.push("/rver/search/results/detail");
  }

  return (
    <Switch>
      <Route path={`${path}${ROUTES.RESULTS}${ROUTES.DETAIL}`}>
        <SearchResultDetail item={selectedItem}/>
      </Route>
      <Route path={`${path}${ROUTES.RESULTS}`}>
        <SearchResultsList searchTerm={searchTerm} searchResults={searchResults} handleItemSelect={handleItemSelect}/>
      </Route> 
      <Route path={`${path}${ROUTES.INDEX}`}>
        <SearchForm handleClick={handleSearchClick}/>
      </Route>
  </Switch>    
  );
}

export default Search;