import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  main: {
    padding: '2rem 0 2rem 2rem',
  },
  bgImage1: {
    borderRadius: '5px',
    height: '100%',
    backgroundPosition: 'center left', 
    backgroundSize: 'cover  ', 
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `linear-gradient(
      rgba(46, 49, 146, 0.4),
      rgba(46, 49, 146, 0.0)
    ),url('/images/rv_palmtreesmed.jpg')`,
    // marginRight: '2rem',
    [theme.breakpoints.down('sm')]: {
      height: '300px',
      backgroundPosition: 'left 90%',
      backgroundSize: 'cover'
    }
  },
  roadBackground: {
    backgroundImage: `linear-gradient(
      rgba(255, 255, 255, 0.98),
      rgba(255, 255, 255, 0.8)
    ),url('/images/HH_CurvyRoad1.png')`,
   
    backgroundRepeat: 'no-repeat',
  },
}));

const About = () => {
  const classes = useStyles();

  return (
    <div className={classes.roadBackground}>
    <Grid className={classes.main} container spacing={3} direction="row" >

      <Grid item xs={12} md={8}>
        <Typography variant='h3' color="textPrimary" align="left" gutterBottom>About</Typography>
        <br/>
        <br/>
        <Typography variant='h4' color="textPrimary" align="left">
          Our Mission
        </Typography>
        <Typography variant='body1' color="textPrimary" align="left">Creating happy campers by connecting them to holy hosts
        </Typography>
        <br/>
        <br/>
        <Typography variant='h4' color="textPrimary" align="left">
          Our Values
        </Typography>
        <Typography variant='body1' component="div" color="textPrimary" align="left">
          We are committed to:
          <ul>
            <li>Doing good &amp; just work</li>
            <li>Play-ful living</li>
            <li>Building community</li>
            <li>Extending a welcome to all</li>
          </ul>
          
        </Typography>
        <br/>
        <br/>
        <Typography variant='h4' color="textPrimary" align="left">
          Our Story
        </Typography>
        <Typography variant='body1' color="textPrimary" align="left">
          Standing on a mountaintop, we had a vision of…<br />
          Nah, we’re kidding.<br /><br />
          We actually just love to camp and we know what it’s like to search for fun and interesting places to stay. Heck, with so many RVs on the road these days, it can be hard to find <em>any</em> place to stay.<br />
          <br />
          We also have a lot of church-y friends, who like to be helpful without being pushy, if you know what we mean. We love them. And we love their parking lots! They are found in the country, in the city and everywhere in between, and they are often located in the most interesting neighborhoods.<br /><br />
          We knew this was a match made in heaven.<br />
          The rest of the story is yours to write.<br />
          <br/>
          Enjoy the journey!
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <div className={classes.bgImage1  }>&nbsp;</div>
      </Grid> 
    </Grid>
    </div>
  );
}

export default About;