import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import FirebaseProvider from './components/Firebase';
import UserProvider from './components/Firebase/UserContext';
import { Provider as ReduxProvider} from 'react-redux';
import store from './redux/configureStore';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#5858e5',
      main: '#002eb2',
      dark: '#000981',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffff6c',
      main: '#F9ED32',
      dark: '#c2bb00',
      contrastText: '#000',
    },
    text: {
      primary: '#000981',
      secondary: '#ffffff'
    }
  },
  typography: {
    h2: {
      fontFamily: [
        '"Bubblegum Sans"',
      ].join(','),
    },
    h3: {
      fontFamily: [
        '"Bubblegum Sans"',
      ].join(','),
    }
  },
});

theme=responsiveFontSizes(theme);

ReactDOM.render(
    // <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ReduxProvider store={store}>
          <FirebaseProvider>
            <UserProvider>
              <App />
            </UserProvider>
          </FirebaseProvider>
        </ReduxProvider>
      </ThemeProvider>
    </BrowserRouter>,
    // </React.StrictMode>,
    document.getElementById('root')
  );

  // If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
