import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../Firebase/UserContext';
import * as ROUTES from '../../constants/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuItem: {
    color: 'black',
  },
  title: {
    flexGrow: 1,
  },
}));

const hostUserLinks = [
  {
    label: 'Host Profile',
    route: ROUTES.HOST + ROUTES.PROFILE,
  }
];

const subscriberUserLinks = [
  {
    label: 'Favorites',
    route: '/',
  },
  {
    label: 'Manage Account',
    route: '/',
  }
];


const UserMenu = () => {
  const classes = useStyles();
  const history = useHistory();
  let { user, isHost, isSubscriber, signOut } = useContext(UserContext);  

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const signOutAndRedirect = () => {
    signOut()
    .then(()=> {
      history.replace(ROUTES.INDEX);
    })
    .catch((error) => {
      console.log('ERROR: User signout failed. ' + error);
    });  
  } 

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {
          isHost ?
          hostUserLinks.map((item, index) => (
            <MenuItem key={index} onClick={ () => history.push(item.route)} className={classes.menuItem}>
              {item.label}
            </MenuItem>
    
          ))
          :
          null
        }
        {
          isHost && isSubscriber ?
          <Divider />
          :
          null
        }
        {
          isSubscriber ?
          subscriberUserLinks.map((item, index) => (
            <MenuItem key={index} onClick={ () => history.push(item.route)} className={classes.menuItem}>
              {item.label}
            </MenuItem>
          ))
          :
          null
        }
        
        <MenuItem onClick={ () => signOutAndRedirect()} className={classes.menuItem}>
          Sign Out
        </MenuItem>
      </Menu>
    </div>
    </>
  );
}

export default UserMenu;