import React from 'react';
import SearchResult from '../SearchResult';
import { Grid, Typography, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import GoogleMap from '../../../GoogleMap';

const useStyles = makeStyles((theme) => ({
  border1: {
    border: '1px solid red',
    marginBottom: '2rem'
  },
  image: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  resultContainer: {
    marginBottom: '1rem',
    [theme.breakpoints.down('xs')]: {
      borderBottom: '1px solid lightgrey',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  }
}));

const SearchResultsList = ({searchTerm, searchResults, handleItemSelect}) => {
  const classes = useStyles();
  const history = useHistory();
  
  return (
    <>
    <IconButton color="primary" aria-label="go back" onClick={() => history.goBack()}>
      <ArrowBackIcon />
    </IconButton>
    <Typography variant="h4" gutterBottom>Results for: {searchTerm.term}</Typography>

    <Grid container spacing={2}>
        
        <Grid item xs={12} md={8} container >
          {
            searchResults.map((item, index) => {
              return (
                <div key={index} className={classes.resultContainer} onClick={() => handleItemSelect(index)}>
                  <SearchResult item={item}/>
                </div>
              );
            })
          }
        </Grid>
        
        <Grid item xs={false} md={4} >
          <GoogleMap center={searchTerm.location} data={searchResults} /> 
        </Grid>

      </Grid>
    </>
  );
}

export default SearchResultsList;
    
    
    // <div className="search-container" style={{display: 'flex', flexDirection:'row'}}>
    //   <div className="results-list" style={{width: '70%', height: '100%'}}>
    //     <h1>A list of search results</h1>
    //     <Grid container spacing={2}>
    //     {
    //           searchResults.map((item, index) => {
    //           return (
    //             <Grid key={index} item><SearchResult item={item} handleSelect={()=>{returnSelectedIndex(index)}}/></Grid>
    //           );
    //         })
    //       }
          
    //     </Grid>
        
         
    //   </div>
    //   <div className="the-map" style={{width: '30%', height: '500px'}}>
    //       <GoogleMap center={searchTerm.location} data={searchResults} />
    //   </div>
    // </div>
