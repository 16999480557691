import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
});

const HostNoData = ({onClick}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} justify="center">

      <Grid item xs={12}>
        <Typography variant='body1' color="textPrimary" align="center" gutterBottom>It looks like you haven't entered your host information. Why not enter some now?</Typography>
        <br />
        <div className={classes.buttonContainer}>
          <Button variant="contained" color="secondary" className="host-btn" onClick={onClick}>Let's do this!</Button>
        </div>
      </Grid> 
    </Grid>
  
  );
}

export default HostNoData;