import React, { createContext } from 'react';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID
};

const FirebaseContext = createContext(null);
export { FirebaseContext };

const FirebaseProvider =  ({children}) => {
  
  if (!app.apps.length) {
    app.initializeApp(config);
    
    //DEBUG
    //app .firestore().useEmulator("localhost", 8080);

  }
  
  return (
    <FirebaseContext.Provider value={ app }>
      {children}
    </FirebaseContext.Provider>
  );
}

export default FirebaseProvider;