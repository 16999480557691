import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Input, InputLabel, FormControl } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';
import { newsletterForm } from '../../util/newsletter'

const useStyles = makeStyles((theme) => ({
  mainImage: {
    height: '350px',
    borderRadius: '5px',
    backgroundImage: `linear-gradient(
      rgba(0, 46, 178, 0.3),
      rgba(0, 46, 178, 0.0)
    ),url('/images/RV_church1.jpg')`,
    backgroundPosition: 'left center', 
    backgroundSize: 'cover', 
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: '13% center', 
    },
    [theme.breakpoints.up('md')]: {
      backgroundPosition: 'left center', 
      height: '350px',
    },
    padding: '3rem',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 4px 6px gray'
  },
  secondaryImage: {
    height: '250px',
    backgroundPosition: 'center center', 
    backgroundSize: 'cover  ', 
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2rem'
  },
  secondaryImage1: {
    backgroundImage: `url('/images/Collar3.png')`,
    backgroundPosition: '85% center', 
    backgroundSize: 'contain',
    paddingRight: '7rem',  
    marginRight: '0.5rem',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0',
    },
  },
  secondaryImage2 : {
    backgroundImage: `url('/images/BackpackersPair.png')`,
    backgroundPosition: '95% center', 
    backgroundSize: 'contain',
    marginLeft: '0.5rem',
    paddingRight: '7rem',  
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0',
    },
    
  },
  emailImage : {
    padding: '2rem',
    borderRadius: '5px',
    marginTop: '1rem',
    height: '300px',
    backgroundImage: `linear-gradient(
      rgba(0, 46, 178, 0.4),
      rgba(0, 46, 178, 0.0)
    ),url('/images/rvmoab_horizmed.jpg')`,
    backgroundPosition: 'center center', 
    [theme.breakpoints.down('md')]: {
      backgroundPosition: 'center center', 
      height: '275px',
    },
    backgroundSize: 'cover', 
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 6px gray'
  },
  emailInput: {
    color: 'white',
    border: '1px solid white',
    padding: '1rem'
  },
  emailInputLabel: {
    color: 'white'
  },
  textShadow: {
    textShadow: '1px 1px 1px black',
  },
  textShadowWhite: {
    textShadow: '0 0 4px white',
  },
  paddingTopBottom: {
    paddingTop: '3rem',
    paddingBottom: '3rem',
  },
  roadBackground: {
    backgroundImage: `linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),url('/images/HH_CurvyRoad1.png')`,
    backgroundSize: 'cover', 
    backgroundRepeat: 'no-repeat',
  },
  linkStyle: {
    textDecoration: 'none'
  }
}));

const Home = () => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = newsletterForm(document, "script", "aweber-wjs-roacec6hr");
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);
  
  return (
    <div id="homeRoadContainer" className={classes.roadBackground}>
    <Grid container >
      <Grid item xs={12}>
        <div className={classes.mainImage}> 
          <Typography className={classes.textShadow} color="textSecondary" variant="h2" align="left" gutterBottom={true}> 
          Stay with the saints.
          </Typography>
          <Typography className={classes.textShadow} color="textSecondary" variant="h4" align="left">
          Join our membership network to stay at churches, community centers and more…for free!
          </Typography>
          <Typography color="textSecondary" variant="h5" align="left" component="div">
            <p></p>
            <Button variant="contained" color="secondary" onClick={() => history.push(ROUTES.RVER)}>
              Learn more!
            </Button>
          </Typography>
        </div>
      </Grid>

      <Grid item xs={1} sm={3} className={classes.paddingTopBottom}>
        {/* <Paper className={classes.paper}></Paper> */}
      </Grid>
      <Grid item xs={10} sm={6} className={classes.paddingTopBottom}>
        {/* <Paper className={classes.paper} elevation={0}> */}
          <Typography variant="h4" color="textPrimary"> 
          Friendly hosts. Safe overnight parking for self-contained RVs. Stay FREE with do-gooders across the country!
          </Typography>
        {/* </Paper> */}
      </Grid>
      <Grid item xs={1} sm={3} className={classes.paddingTopBottom}>
        {/* <Paper className={classes.paper}></Paper> */}
      </Grid>

      <Grid item xs={12} sm={6}>
        <div className={`${classes.secondaryImage} ${classes.secondaryImage1}`} onClick={() => history.push(ROUTES.HOST)}> 
        <Link to={ROUTES.HOST} className={classes.linkStyle}>
          <Typography color="textPrimary" variant="h3" align="left" className={classes.textShadowWhite}>
            Become a host
          </Typography>
          </Link>
          <Typography color="textSecondary" variant="h3" align="left">
          &nbsp;
          </Typography>
        </div>  
      </Grid>
      <Grid item xs={12} sm={6} >
        <div className={`${classes.secondaryImage} ${classes.secondaryImage2}`} onClick={() => history.push(ROUTES.RVER)}> 
        <Link to={ROUTES.RVER} className={classes.linkStyle}>
          <Typography color="textPrimary" variant="h3" align="left" className={classes.textShadowWhite}>
          Wander with us
          </Typography>
        </Link>
          <Typography color="textSecondary" variant="h3" align="left">
          &nbsp;
          </Typography>
        </div>  
      </Grid>
      
      <Grid item xs={12} >
        <div className={classes.emailImage}> 
        <Typography color="textSecondary" variant="caption" align="left">&nbsp;</Typography>
        <Typography className={classes.textShadow} color="textSecondary" variant="h3" align="left"> 
            Holy Hosts News
          </Typography>
          <Typography className={classes.textShadow} color="textSecondary" variant="h4" align="left">
            Sign up to receive news and updates.
          </Typography>
          <div className="AW-Form-2091183020"></div>
        </div>
      </Grid>
    </Grid>
    </div>
  );
}

export default Home;