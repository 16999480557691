import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  main: {
    padding: '3rem',
  },
  bgImage1: {
    borderRadius: '5px',
    height: '300px',
    backgroundPosition: 'center center', 
    backgroundSize: 'cover', 
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url('/images/sunset1.jpg')`,
    [theme.breakpoints.down('sm')]: {
      height: '300px',
      backgroundPosition: 'left 90%',
      backgroundSize: 'cover'
    }
  },  
}));
const Contact = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.main} container spacing={3} direction="row">

      <Grid item xs={12} md={8} >
        <Typography variant='h3' color="textPrimary" align="left" gutterBottom>Contact</Typography>
        <br />
        <Typography variant='h4' color="textPrimary" align="left" gutterBottom>Get in touch</Typography>
        <Typography variant='body1' color="textPrimary" align="left" gutterBottom>
          Ask us a question. Share your Holy Host experience. Tell us what you think of The Holy Hosts™!
        </Typography>
        <Typography variant='h6' color="textPrimary" align="left" gutterBottom>
          info@TheHolyHosts.com
        </Typography>
        <br />
        
      </Grid>
      <Grid item xs={12} md={4} >
        <div className={classes.bgImage1} />

      </Grid>
    </Grid>
  );
}

export default Contact;