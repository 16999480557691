import React, {useContext} from 'react';
import { FirebaseContext } from '../../Firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'; 
import '../rver.css'; 
import * as ROUTES from '../../../constants/routes';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';


const RVerSignUp = () => {
  const history = useHistory();
  const firebase = useContext(FirebaseContext);
  const func = firebase.functions();
  // const setRVre = func.httpsCallable('setRVer');

  var uiConfig = {    
    callbacks: {
      signInSuccessWithAuthResult: function(authResult, redirectUrl) {
        console.log('signInSuccessWithAuthResult');
        
        history.push(ROUTES.RVER+ROUTES.SUBSCRIBE);
       
        return false;
      },
      // signInFailure: function(error) {
      //   console.log('signInFailure');
      //   // Some unrecoverable error occurred during sign-in.
      //   // Return a promise when error handling is completed and FirebaseUI
      //   // will reset, clearing any UI. This commonly occurs for error code
      //   // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
      //   // occurs. Check below for more details on this.
      //   return handleUIError(error);
      // },
      // uiShown: function() {
      //   console.log('uiShown');
      //   // The widget is rendered.
      //   // Hide the loader.
      //   // document.getElementById('loader').style.display = 'none';
      // }
    },  
    signInSuccessUrl: '/rver/search',
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,    
    ],
    // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    // Terms of service url/callback.
    tosUrl: ROUTES.TERMS,
    // Privacy policy url/callback.
    privacyPolicyUrl: function() {
      history.push(ROUTES.PRIVACY);
    }
  }
  
  
  return (
    <Grid container spacing={3} justify="center">
      <Grid item xs={12}>
        <Typography variant='h3' color="textPrimary" align="center" gutterBottom>Become a Member</Typography>
      </Grid> 
      <Grid item xs={12}>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
      </Grid> 
    </Grid>

  );
}

export default RVerSignUp;