import React from 'react';
import { Grid, Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '20rem',
  },
}));

const SearchForm = ({handleClick}) => {
  const classes = useStyles();
  const doSomething = () => {
    let el = document.getElementById('search-term');
    handleClick(el.value);
  };

  return (
    
    <Grid container spacing={3}>
      <Grid item xs={false} sm={2}>
      </Grid>
      
      <Grid item xs={12} sm={8} >
        <Typography variant='h3' color="textPrimary" gutterBottom>Where are you headed?</Typography>
          <TextField className={classes.input} id="search-term" color="primary" label="Search Term" type="search" variant="outlined"/>
          <br />
          <br />
          <Button variant="contained" color="secondary" onClick={doSomething}>Find me a place to park!</Button>
      </Grid>
      
      <Grid item xs={false} sm={2}>
      </Grid>
   
    </Grid>
  );
}

export default SearchForm;