import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { UserContext } from '../Firebase/UserContext'
import UserMenu from './UserMenu';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
}));

// const menuLinks = [
//   {
//     label: 'Host Profile',
//     route: ROUTES.HOST + ROUTES.PROFILE,
//   }
// ];

const HostNavigation = ()=> {
  const classes = useStyles();
  let history = useHistory();
  let {user} = useContext(UserContext);

  return (
    <>
      <Typography variant="h6" className={classes.title}></Typography>
      {user ?
         <UserMenu />
         :
      <>
        <Button color="inherit" onClick={() => {history.push(ROUTES.HOST + ROUTES.SIGN_IN)}}>Log In</Button>
        <Button color="inherit" onClick={() => {history.push(ROUTES.HOST + ROUTES.SIGN_UP)}}>Become a Host</Button>
      </>
      }  
    </>
  );
}

export default HostNavigation;