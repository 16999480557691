import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  
  bgImg: {
    backgroundImage: `linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),url('/images/esteschurchmed.jpg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'right center',
    padding: '2rem 2rem 2rem 2rem'
  },
  gridElement: {
    padding: '0.5rem'
  },
  textShadow: {
    textShadow: '1px 1px 1px black',
  }
}));

const Commandments = () => {
  const classes = useStyles();

  return (
    
      <Grid container justify="center" className={classes.bgImg}>

        <Grid item xs={12} className={classes.gridElement}>
          <Typography color='textPrimary'  variant='h3' align="center" gutterBottom>The Holy Hosts 10 Commandments</Typography>
        </Grid> 
      
        
        <Grid item xs={12} sm={6} md={4} lg={3} className={classes.gridElement}>
          <Typography color='textPrimary' variant='h5' align="center" gutterBottom>1</Typography>
          <Typography color='textPrimary' variant='h5' align="left" gutterBottom>Thou shalt welcome everyone!</Typography>
          <Typography color='textPrimary' variant='body1' align="left">Our hosts welcome, honor and respect everyone regardless of race, ethnicity, religion, gender identity or sexual orientation. Well, except for bullies. They can stay somewhere else.</Typography>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4} lg={3} className={classes.gridElement}>
          <Typography color='textPrimary' variant='h5' align="center" gutterBottom>2</Typography>
            <Typography color='textPrimary' variant='h5' align="left" gutterBottom>Thou shalt show, not tell</Typography>
            <Typography color='textPrimary' variant='body1' align="left">The loving hospitality of our hosts is a witness to the faith and values they hold dear, but there is no preaching (or even “preachiness”). Our hosts know actions speak a whole lot louder than words.</Typography>
          </Grid>
        
          <Grid item xs={12} sm={6} md={4} lg={3} className={classes.gridElement}>
          <Typography color='textPrimary' variant='h5' align="center" gutterBottom>3</Typography>
          <Typography color='textPrimary' variant='h5' align="left" gutterBottom>Thou shalt show gratitude </Typography>
            <Typography color='textPrimary' variant='body1' align="left">Our RVers show gratitude for their host’s generosity by making a voluntary donation to support the host’s work in their community, treating the host’s property and neighbors with respect, and following the host’s rules. </Typography>
          </Grid>
      
          <Grid item xs={12} sm={6} md={4} lg={3} className={classes.gridElement}>
          <Typography color='textPrimary' variant='h5' align="center" gutterBottom>4</Typography>
           <Typography color='textPrimary' variant='h5' align="left" gutterBottom>Thou shalt be a good neighbor</Typography>
            <Typography color='textPrimary' variant='body1' align="left">Our hosts are responsible for knowing and abiding by any and all relevant local laws and ordinances. Also, it’s never a bad idea to let your neighbors know you’re having company, especially if they’re used to watching out for you and your property. </Typography>
          </Grid>
        
          <Grid item xs={12} sm={6} md={4} lg={3} className={classes.gridElement}>
          <Typography color='textPrimary' variant='h5' align="center" gutterBottom>5</Typography>
          <Typography color='textPrimary' variant='h5' align="left" gutterBottom>Thou shalt remember our furry friends</Typography>
            <Typography color='textPrimary' variant='body1' align="left">Many RVers travel with their pets. Most hosts will welcome them but some will not be able to do that. Hosts will set expectations up front and guests will honor them because…see commandment #6.</Typography>
          </Grid>
        
          <Grid item xs={12} sm={6} md={4} lg={3} className={classes.gridElement}>
          <Typography color='textPrimary' variant='h5' align="center" gutterBottom>6</Typography>
          <Typography color='textPrimary' variant='h5' align="left" gutterBottom>Thou shalt treat others as you want to be treated</Typography>
            <Typography color='textPrimary' variant='body1' align="left">This is the Golden Rule. When in doubt about what to do, do this.</Typography>
          </Grid>
      
          <Grid item xs={12} sm={6} md={4} lg={3} className={classes.gridElement}>
          <Typography color='textPrimary' variant='h5' align="center" gutterBottom>7</Typography>
          <Typography color='textPrimary' variant='h5' align="left" gutterBottom>Thou shalt remember: Size matters</Typography>
            <Typography color='textPrimary' variant='body1' align="left">Most RVs are 20-45 ft long, 8.5 ft wide, and 14 ft tall. Some are bigger. Our hosts will let guests know what they can - and can’t -accommodate.</Typography>
          </Grid>
        
          <Grid item xs={12} sm={6} md={4} lg={3} className={classes.gridElement}>
          <Typography color='textPrimary' variant='h5' align="center" gutterBottom>8</Typography>
          <Typography color='textPrimary' variant='h5' align="left" gutterBottom>Thou shalt try to be a joyful traveler</Typography>
            <Typography color='textPrimary' variant='body1' align="left">Our host and RV members aren’t angels but we all do our best to share love and joy wherever our travels take us.</Typography>
          </Grid>
        
          <Grid item xs={12} sm={6} md={4} lg={3} className={classes.gridElement}>
          <Typography color='textPrimary' variant='h5' align="center" gutterBottom>9</Typography>
          <Typography color='textPrimary' variant='h5' align="left" gutterBottom>Thou shalt remember there is a little saint and a little sinner in all of us</Typography>
            <Typography color='textPrimary' variant='body1' align="left">This helps us all be graceful when something goes wrong. It doesn’t happen often but, when it does, we are patient and kind to each other.</Typography>
          </Grid>
        
          <Grid item xs={12} sm={6} md={4} lg={3} className={classes.gridElement}>
          <Typography color='textPrimary' variant='h5' align="center" gutterBottom>10</Typography>
          <Typography color='textPrimary' variant='h5' align="left" gutterBottom  >Thou shalt avoid surprises</Typography>
            <Typography color='textPrimary' variant='body1' align="left">Our hosts lovingly and clearly communicate expectations - hook-ups or not, quiet hours, arrival &amp; departure time, etc. - in advance. And our guests honor these expectations because…see commandment #6.</Typography>
          </Grid>

      </Grid>
  
  );
}

export default Commandments;