import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as ROUTES from '../../../constants/routes';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../Firebase/UserContext';

const useStyles = makeStyles((theme) => ({
  bgImage1: {
    borderRadius: '5px',
    height: '100%',
    backgroundPosition: 'center center', 
    backgroundSize: 'cover  ', 
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url('/images/wanderersmed.jpg')`,
    marginRight: '2rem',
    [theme.breakpoints.down('sm')]: {
      height: '300px',
      backgroundSize: 'contain'
    }
  },
  somePadding: {
    padding: '2rem 0 2rem 2rem',
  },
  roadBackground: {
    backgroundImage: `linear-gradient(
      rgba(255, 255, 255, 0.98),
      rgba(255, 255, 255, 0.8)
    ),url('/images/HH_CurvyRoad1.png')`,
   
    backgroundRepeat: 'no-repeat',
  },
  
}));

const RVerLanding = () => {
  const classes = useStyles();
  const history = useHistory();
  let { user } = useContext(UserContext);

  return (
    <div className={classes.roadBackground}>
    <Grid container spacing={3} justify="center" direction="row" className={classes.somePadding}>

      <Grid item xs={12} md={8}>
        <Typography variant='h3' color="textPrimary" align="left" gutterBottom>Welcome, wanderers!</Typography>
        <Typography variant='body1' color="textPrimary" align="left" >
          The heavens just opened and dropped a gift into your traveling life. There is a growing network of churches, community centers and other do-gooders who are ready to let you park your Self-Contained RV overnight…<strong>for free.</strong>
        </Typography>
        <br />
        <Typography variant='body1' color="textPrimary" align="left" >
          You’re welcome!
        </Typography>
        <br />
        <Typography variant='body1' color="textPrimary" align="left" >
          Also, <strong>YOU (and your self-contained RV!) are welcome</strong>…whatever you look like, wherever you come from, whoever you love, and however you worship or even if you do at all. 
          </Typography>
        <br />
        <Typography variant='h5' color="textPrimary" align="left" >
             How does it work?
        </Typography>
        <br />
        <Typography variant='body1' color="textPrimary" align="left" >     
          When you become a member of The Holy Hosts™ and agree to abide by <Link to="/commandments">The Holy Hosts Ten Commandments</Link>, you get access to our <strong>nationwide host directory</strong>.
          When you find a place you’d like to stay, you’ll contact the host directly by phone. They will let you know whether or not they are able to accommodate you on the date you’re requesting. If it’s a “go,” the host will give you their expectations, which are designed to make sure this is a good experience for everyone.
          At the end of your stay, <strong>you are encouraged to make a voluntary donation</strong> to support the work your host is doing in their community. Especially when you think about how much $$ you’re saving by staying with these good folks, you’re going to want to pitch in to help!
        </Typography> 
        <br />    
        <Typography variant='h5' color="textPrimary" align="left" >
          How much does it cost to join?
        </Typography> 
        <br />    
        <Typography variant='body1' color="textPrimary" align="left" >  
        An annual membership in The Holy Hosts™ network is just $69.
        </Typography>     
        <br />
        <Typography variant='body1' color="textPrimary" align="left" >  
          <strong>$69 for a whole year!</strong>
        </Typography>     
        <br />
        <Typography variant='body1' color="textPrimary" align="left" >  
          Some of us have paid that much to stay in an RV park for <em>one night</em>. 
          Your membership will “pay for itself” after just a night or two with The Holy Hosts™.
        </Typography>
        <br />
        <Typography  variant='h5' color="textPrimary" >Coming soon!</Typography>
        <Button variant="contained" disabled color="secondary" onClick={() => history.push(ROUTES.RVER + ROUTES.SIGN_UP)}>Join</Button>
      </Grid> 
      
      <Grid item xs={12} md={4}>
        <div className={classes.bgImage1}></div>
      </Grid> 
    </Grid>
    </div>
  );
}

export default RVerLanding;