import React, { createContext, useContext, useState } from 'react';
import { useEffect } from 'react';
import { FirebaseContext } from '.';
import * as CONSTANTS from '../../constants/constants';

const UserContext = createContext(null);
export { UserContext };

const UserProvider =  ({children}) => {
  let [user, setUser] = useState(null);
  let [isHost, setIsHost] = useState(false);
  let [isSubscriber, setIsSubscriber] = useState(false);

  let [loading, setLoading] = useState(true);
  let firebase = useContext(FirebaseContext);

  const signOut = (newRoute)  => {
    setLoading(true);
    return firebase.auth().signOut();
  } 

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUser(user);
        user.getIdTokenResult()
        .then((idTokenResult) => {
          // Confirm the user is an Admin.
          if (!!idTokenResult.claims.isHost) {
            setIsHost(true);
          } 
          
          if (idTokenResult.claims.stripeRole && idTokenResult.claims.stripeRole === CONSTANTS.SUBSCRIBER ) {
            setIsSubscriber(true);
          } 

          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
        
      } else {
        setUser(null);
        setIsHost(false);
        setIsSubscriber(false);
        setLoading(false);
      }
    });

    return () => {
      unsubscribe();
    }    
  },[firebase]);
  
  return (
    <UserContext.Provider value={ {user, isHost, isSubscriber, loading, signOut} }>
      {children}
    </UserContext.Provider>
  );
}

export default UserProvider;