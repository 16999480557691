import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { UserContext } from '../Firebase/UserContext';

import UserMenu from './UserMenu';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
}));

const TopNavigation = ()=> {
  const classes = useStyles();
  let history = useHistory();
  let {user, isHost, isSubscriber} = useContext(UserContext);
  
  return (
    <>
      <Typography variant="h6" className={classes.title}>
      </Typography>
      <Button color="inherit" onClick={() => {history.push(ROUTES.HOST)}}>Hosts</Button>
      <Button color="inherit" onClick={() => {history.push(ROUTES.RVER)}}>Wanderers</Button>
      {user ?
         <UserMenu />
         :
      null}
    </>
  );
}

export default TopNavigation;