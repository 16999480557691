import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '2rem'
  },
  
}));

const Terms = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant='h3' color='textPrimary'>
        Terms of Service ("Terms")
      </Typography>

      <Typography variant='h4' color='textPrimary'>
        Last updated: 4/1/2021
      </Typography>
      
      <Typography variant='body1' color='textPrimary'>
        <p>Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the https://theholyhosts.com website and The Holy Hosts mobile application (the "Service") operated by The Holy Hosts ("us", "we", or "our").
        </p>
        <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.
        </p>

        <p><strong>
        By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.
        </strong></p>

        <p><strong>Termination</strong></p>
        <p>We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>

        <p>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability. </p>
      </Typography>


      <Typography variant='body1' color='textPrimary'>
      <p><strong>Subscriptions</strong></p>
      <p>Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring annual basis. You cannot cancel your subscription by email or phone call. To cancel, you must log into The Holy Hosts, select your account, and follow the instructions to cancel.
      </p>

      <Typography variant='body1' color='textPrimary'>
        <p><strong>Links To Other Web Sites</strong></p>
        <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by The Holy Hosts.
        </p>
        <p>The Holy Hosts has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that The Holy Hosts shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.
        </p>
      </Typography>
      
      <Typography variant='body1' color='textPrimary'>
        <p><strong>Changes</strong></p>
        <p>
          We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 7 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
        </p>
  
      </Typography>

      <Typography variant='body1' color='textPrimary'>
       <p><strong>Contact Us</strong></p>
       <p>If you have any questions about these Terms, please contact us at info@theholyhosts.com
       </p>
      </Typography>
</Typography>
 

 

    </div>
  );
}

export default Terms;