import React from 'react';
import Home from '../Home';
import RVer from '../RVer';
import Host from '../Host';
import SignIn from '../User/SignIn';
import About from '../About';
import Contact from '../Contact';
import Legal from '../Legal';
import Commandments from '../Commandments';
import { Container, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as ROUTES from '../../constants/routes';
import { Switch, Route } from 'react-router-dom';
import Privacy from '../Privacy';
import Terms from '../Terms';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    color: theme.palette.text.secondary,
    backgroundImage: `linear-gradient(
      rgba(46, 49, 146, 0.0),
      rgba(46, 49, 146, 0.2) 90%
    )`,
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0
  },
}));
const Main = ()=> {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Paper square={true} className={classes.paper}>
          <Switch>
            <Route path={ROUTES.LOGIN}>
                <SignIn/>
            </Route> 
            <Route path={ROUTES.HOST}>
                <Host/>
            </Route> 
            <Route path={ROUTES.RVER}>
                <RVer/>
            </Route> 
            <Route path={ROUTES.ABOUT}>
                <About/>
            </Route> 
            <Route path={ROUTES.CONTACT}>
                <Contact/>
            </Route> 
            <Route path={ROUTES.LEGAL}>
                <Legal/>
            </Route> 
            <Route path={ROUTES.COMMANDMENTS}>
                <Commandments/>
            </Route>
            <Route path={ROUTES.PRIVACY}>
                <Privacy/>
            </Route> 
            <Route path={ROUTES.TERMS}>
                <Terms/>
            </Route> 
            <Route path={ROUTES.INDEX}>
                <Home /> 
            </Route>
          </Switch>
        </Paper>
      </Container>
    </div> 
  );
}

export default Main;