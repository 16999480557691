const LOADING_START = 'loading_start';
const LOADING_END = 'loading_end';

export const setLoadingStart = () => ({
  type: LOADING_START
})

export const setLoadingEnd = () => ({
  type: LOADING_END 
})

const initialState = {
  isLoading: false
};

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_START:
      return {...state, isLoading: true}
    case LOADING_END:
      return {...state, isLoading: false}
    default: 
      return state;
  }
}

export default loadingReducer;