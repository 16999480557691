import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../Firebase/UserContext';
import { FirebaseContext} from '../../Firebase';
import HostDataEdit from './hostDataEdit';
import HostDataView from './hostDataView';
import HostNoData from './hostNoData';
import { HostFieldsRequired } from '../../../models/models'; 
import HostLoading from '../../Loading/HostLoading';
import { Button, Typography } from '@material-ui/core';
import { formatPhone, stringToBool } from '../../../util/format';

const HostProfile = () => {
  const firebase = useContext(FirebaseContext);
  const firestore = firebase.firestore();
  const storage = firebase.storage();
  let [loading, setLoading] = useState(true);

  const maxImageSize = 2621440; //2.5mb 

  let {user} = useContext(UserContext);
  
  const [hostData, setHostData] = useState({});
  const [currentHostData, setCurrentHostData] = useState({});
  // let [emailVerifiied, setEmailVerified] = useState(false);
  let [docFound, setDocFound] = useState(false);
  let [isEditMode, setEditMode] = useState(false);
  let [errorMessages, setErrorMessages] = useState([]);
  let [errorFields, setErrorFields] = useState({});
  
  
  useEffect(() => {
    if (user) {
      
      var docRef = firestore.collection("hosts").doc(user.uid);
      docRef.get().then(function(doc) {
        if (doc.exists) {
          setDocFound(true);
          setHostData(doc.data());
          setCurrentHostData(doc.data());
  
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
        
        setLoading(false);
  
      });
    } 
  }, [user, firestore]);

  const handleFileSelect = async (e) => {
    setErrorFields([]);
    const fileId = e.target.id;
    const filePicker = e.target;
    if (!filePicker || 
        !filePicker.files ||
        filePicker.files.length <= 0) {
        return;
    }
    const myFile = filePicker.files[0];
    if (myFile.size > maxImageSize) {
      console.log('ugh! the file is too big!');
      
      if (!errorFields.includes(fileId)){
        let newErrorFields = [...errorFields]
        newErrorFields.push(fileId);
        setErrorFields(newErrorFields);
      }

      return;
    }
    
    //TODO: verify file extension
    let idx = myFile.name.lastIndexOf('.');
    let extension = myFile.name.substring(idx);
    
    let fileName = fileId + extension;
    
    const storagePathAndFilename = `${user.uid}/${fileName}`
  
    let url = await uploadFile(myFile, storagePathAndFilename);
  
    let fieldIndex = fileId.charAt(fileId.length - 1);
    switch (fieldIndex) {
      case '1': setCurrentHostData({...currentHostData, hostImage1: url });
        break;
      case '2': setCurrentHostData({...currentHostData, hostImage2: url });
        break;
      case '3': setCurrentHostData({...currentHostData, hostImage3: url });
        break;
      default:
        break;
    } 
        
  }

  const uploadFile = async (myFile, path) => {
    try {        
      const ref = storage.ref(path);
      await ref.put(myFile);
      const myDownloadUrl = await ref.getDownloadURL();
      
      return myDownloadUrl;

    } catch (err) {
      console.log('ERROR: Unable to save image ' + err);
    }
  }

  const handleInputChange = (e) => {
    let identifier = e.target.id ? e.target.id : e.target.name;

    switch(identifier) {
      case 'churchName':
        setCurrentHostData({...currentHostData, churchName: e.target.value });
        break;
      case 'signupName':
        setCurrentHostData({...currentHostData, signupName: e.target.value });
        break;
      case 'signupTitle':
        setCurrentHostData({...currentHostData, signupTitle: e.target.value });
        break;
      case 'signupPhone':
        setCurrentHostData({...currentHostData, signupPhone: e.target.value });
        break;
      case 'signupEmail':
        setCurrentHostData({...currentHostData, signupEmail: e.target.value });
        break;
      case 'address1': 
        setCurrentHostData({...currentHostData, address1: e.target.value });
        break;
      case 'address2': 
        setCurrentHostData({...currentHostData, address2: e.target.value });
        break;
      case 'city': 
        setCurrentHostData({...currentHostData, city: e.target.value });
        break;
      case 'state': 
        setCurrentHostData({...currentHostData, state: e.target.value });
        break;
      case 'zipcode':
        setCurrentHostData({...currentHostData, zipcode: e.target.value });
        break;
      case 'website':
          setCurrentHostData({...currentHostData, website: e.target.value });
          break;
      case 'callPhone':
          setCurrentHostData({...currentHostData, callPhone: e.target.value });
          break;
      case 'textPhone':
          setCurrentHostData({...currentHostData, textPhone: e.target.value });
          break;
      case 'email': 
        setCurrentHostData({...currentHostData, email: e.target.value });
        break;
      case 'contactPerson':
          setCurrentHostData({...currentHostData, contactPerson: e.target.value });
          break;
      case 'reservationLeadTime':
          setCurrentHostData({...currentHostData, reservationLeadTime: e.target.value });
          break;
      case 'nightsAvailable':
          setCurrentHostData({...currentHostData, nightsAvailable: e.target.value });
          break;
      case 'blackoutDates':
          setCurrentHostData({...currentHostData, blackoutDates: e.target.value });
          break;
      case 'donationLink':
          setCurrentHostData({...currentHostData, donationLink: e.target.value });
          break;
      case 'altDonationInstructions':
          setCurrentHostData({...currentHostData, altDonationInstructions: e.target.value });
          break;
      case 'quietHours':
          setCurrentHostData({...currentHostData, quietHours: e.target.value });
          break;
      case 'pets': 
        setCurrentHostData({...currentHostData, pets: e.target.value });
        break;
      case 'children': 
        setCurrentHostData({...currentHostData, children: e.target.value });
        break;
      case 'electricity':
        setCurrentHostData({...currentHostData, electricity: e.target.value });
        break; 
      case 'water':
        setCurrentHostData({...currentHostData, water: e.target.value });
        break; 
      case 'rvMaxLength':
        setCurrentHostData({...currentHostData, rvMaxLength: parseInt(e.target.value, 10) });
        break; 
      case 'rvMaxWidth':
        setCurrentHostData({...currentHostData, rvMaxWidth: parseInt(e.target.value,10) });
        break; 
      case 'rvMaxHeight':
        setCurrentHostData({...currentHostData, rvMaxHeight: parseInt(e.target.value, 10) });
        break; 
      case 'specialNotes':
        setCurrentHostData({...currentHostData, specialNotes: e.target.value });
        break;
      case 'maxStay':
        setCurrentHostData({...currentHostData, maxStay: parseInt(e.target.value, 10) });
        break; 
      case 'minDonation':
        setCurrentHostData({...currentHostData, minDonation: parseInt(e.target.value, 10) });
        break;
      case 'otherServices':
        setCurrentHostData({...currentHostData, otherServices: e.target.value });
        break; 
      case 'volunteerOpportunities':
        setCurrentHostData({...currentHostData, volunteerOpportunities: e.target.value });
        break; 
      case 'guestRules': 
        setCurrentHostData({...currentHostData, guestRules: e.target.value });
        break;
      default:
        console.log('what the?!');  
    }

  }

  
  const handleSubmit = (e) => {
    let isValid = validateInput();
    let fullAddr;

    if (isValid) {
      doSave(currentHostData);
    } else {
      let errorContainer = document.getElementsByClassName('error-container')[0];
      errorContainer.classList.remove('error-container-hide');
      window.scrollTo(0, 0);
   }
  }

  const clearErrors = () => {
    setErrorMessages([]);
    setErrorFields([]);
    
    let errorContainer = document.getElementsByClassName('error-container')[0];
    errorContainer.classList.add('error-container-hide');
  }

  const validateInput = () => {
    var isValid = true;
    var errorMsgs = [];
    clearErrors();

    let emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    let phonePattern = new RegExp(/(?:\d{3}|\(\d{3}\))\s*[-/.]?\s*\d{3}[-/.\s]?\s*\d{4}$/);

    if (!currentHostData.hasOwnProperty('signupName') && 
      !currentHostData.hasOwnProperty('signupTitle') &&
      !currentHostData.hasOwnProperty('signupPhone')) {
        errorMsgs.push('Your name, title, and phone number are required');
        isValid = false;
    }

    if (!currentHostData.hasOwnProperty('website')) {
        errorMsgs.push('Website is required');
        isValid = false;
    }
    
    if (!currentHostData.hasOwnProperty('callPhone') && 
      !currentHostData.hasOwnProperty('textPhone') &&
      !currentHostData.hasOwnProperty('email')) {
        errorMsgs.push('At least one reservation method (call, text, or email) is required');
        isValid = false;
    }

    if (!currentHostData.hasOwnProperty('rvMaxLength') && 
      !currentHostData.hasOwnProperty('rvMaxWidth') &&
      !currentHostData.hasOwnProperty('rvMaxHeight')) {
        errorMsgs.push('RV maximum length, height, and width are required');
        isValid = false;
    }
  
    for (const key in HostFieldsRequired) {
      if (key === 'email') {
        if (currentHostData["email"]) {
          if (!emailPattern.test(currentHostData["email"])) {
            isValid = false;
            errorMsgs.push('Email is not a valid format.');
          }
        }
      }
      if (key === 'callPhone') {
        if (currentHostData["callPhone"]) {
          if (!phonePattern.test(currentHostData["callPhone"])) {
            isValid = false;
            errorMsgs.push('Calling option phone number is invalid. Please enter in the form (999) 999-9999');
          }
        }
      }

      if (key === 'textPhone') {
        if (currentHostData["textPhone"]) {
          if (!phonePattern.test(currentHostData["textPhone"])) {
            isValid = false;
            errorMsgs.push('Text option phone number is invalid. Please enter in the form (999) 999-9999');
          }
        }
      }

      if (key === 'signupPhone') {
        if (currentHostData["signupPhone"]) {

          if (!phonePattern.test(currentHostData["signupPhone"])) {
            isValid = false;
            errorMsgs.push('Your phone number is invalid. Please enter in the form (999) 999-9999');
          }
        }
      }

      if (key === 'address1' || key === 'city' || key === 'state' || key === 'zipcode') {
        if (!currentHostData.hasOwnProperty(key)) {
            errorMsgs.push(HostFieldsRequired[key] + ' is required');
            isValid = false;
        
        }
      } 

    }  

    

    setErrorMessages(errorMsgs);

    return  isValid;
  }

  const removePhoneFormatting = (inPhone) => {
    let arr = Array.from(inPhone);
    let arrOut = [];
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] == ' ' || isNaN(arr[i]) ) {
        continue;
      }

      arrOut.push(arr[i]);

    }

    let outPhone = arrOut.join('')
    return outPhone;
  }

  const doSave = (hostDataToSave) => {
    
    firestore.collection('hosts').doc(user.uid).set({
      churchName: hostDataToSave.churchName || '',
      signupName: hostDataToSave.signupName || '',
      signupTitle: hostDataToSave.signupTitle || '',
      signupPhone: hostDataToSave.signupPhone ? removePhoneFormatting(hostDataToSave.signupPhone) : '',
      signupEmail: hostDataToSave.signupEmail || '',
      
      address1: hostDataToSave.address1 || '',  
      address2: hostDataToSave.address2 || '',  
      city: hostDataToSave.city || '',  
      state: hostDataToSave.state || '',  
      zipcode: hostDataToSave.zipcode || '',
      
      website: hostDataToSave.website || '',
      callPhone: hostDataToSave.callPhone ? removePhoneFormatting(hostDataToSave.callPhone) : '',
      textPhone: hostDataToSave.textPhone ? removePhoneFormatting(hostDataToSave.textPhone) : '',
      email: hostDataToSave.email || '',
      contactPerson: hostDataToSave.contactPerson || '',
      
      reservationLeadTime: hostDataToSave.reservationLeadTime || '',
      nightsAvailable: hostDataToSave.nightsAvailable || '',
      blackoutDates: hostDataToSave.blackoutDates || '',
      
      donationLink: hostDataToSave.donationLink || '',
      altDonationInstructions: hostDataToSave.altDonationInstructions || '',
      quietHours: hostDataToSave.quietHours || '',

      pets: stringToBool(hostDataToSave.pets),
      children: stringToBool(hostDataToSave.children),
      electricity: stringToBool(hostDataToSave.electricity),
      water: stringToBool(hostDataToSave.water),
      
      rvMaxLength: hostDataToSave.rvMaxLength,
      rvMaxWidth: hostDataToSave.rvMaxWidth,
      rvMaxHeight: hostDataToSave.rvMaxHeight,
      
      specialNotes: hostDataToSave.specialNotes || '',
      maxStay: hostDataToSave.maxStay || 0,
      minDonation: hostDataToSave.minDonation || 0,
      otherServices: hostDataToSave.otherServices || '',
      volunteerOpportunities: hostDataToSave.volunteerOpportunities || '',
      guestRules: hostDataToSave.guestRules || '',

      hostImage1: hostDataToSave.hostImage1 || '',
      hostImage2: hostDataToSave.hostImage2 || '',
      hostImage3: hostDataToSave.hostImage3 || ''
    }, {merge: true })
    .then(function() {
        console.log("Document successfully written!");
        window.location.reload();
    })
    .catch(function(error) {
        console.error("Error writing document: ", error);
    });
  }

  const toggleEditMode = () => {
    let newVal = !isEditMode;
    setEditMode(newVal);

    clearErrors();
    setCurrentHostData({...hostData});
  }


  if (loading) {
    return (
      <HostLoading />
    );
  }
  
  return (
    <div className="host-profile-container">
      <div className="error-container error-container-hide">
        Hmmmm...  You're close, but we can't quite save your data:
        <ul id="error-msg-list">
          {
            errorMessages.map((item, index) => {
              return <li key={index}>{item}</li>;
            })
          }
        </ul>
      </div>
      <div className="host-header-container">
        <Typography variant="h3" color='textPrimary'>
        Host Information
        </Typography>
        <div className="host-greeting">
          <Typography variant="h4" color='textPrimary'>
            Hi there, {user ? user.displayName : ' person of anonymous description'}            
          </Typography>
        </div>
        
        <div className="host-btn-container"> 
          {docFound && !isEditMode ?
            <Button variant="contained" color="secondary" id="host-edit-btn" onClick={toggleEditMode}>Edit</Button>
            : null
          }       
        </div>
      </div>
      {
        !docFound && !isEditMode ?
        <HostNoData onClick={toggleEditMode}/>
        : null
      }
      {
        isEditMode ?
        <HostDataEdit hostData={currentHostData ? currentHostData : {}} 
                      doOnChange={handleInputChange} 
                      doSubmit={handleSubmit}
                      toggleEdit={toggleEditMode}
                      doFileSelect={handleFileSelect}
                      errorFields={errorFields}/>
        : null
      }
      {
        docFound && !isEditMode ?
        <HostDataView hostData={hostData ? hostData : {} }
                      formatPhone={formatPhone}/>
        : null

      }
    </div>
  );
}

export default HostProfile;