export const formatPhone = (inPhone) => {
  let inArr = Array.from(inPhone);  
  let outArr = [];
  for (var i=0; i < inArr.length; i++) {
    switch (i) { 
      case 0: 
        outArr.push('(');
        break;
      case 3: 
        outArr.push(') ');
        break;
      case 6: 
        outArr.push('-');
        break;
      default:
        break;
    }

    outArr.push(inArr[i]);
  }

  let outPhone = outArr.join('');
  return outPhone;
}    

export const stringToBool = (input) => {
  
  if (input === true || input === 'true') {
    return true;
  }

  return false;
} 