import React, {useContext} from 'react';
import { FirebaseContext } from '../../Firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'; 
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';

const HostSignIn = () => {
  const history = useHistory();
  const firebase = useContext(FirebaseContext);
  const func = firebase.functions();
  const setHost = func.httpsCallable('setHost');

  var uiConfig = {    
    callbacks: {
      signInSuccessWithAuthResult: function(authResult, redirectUrl) {
        console.log('hostSignIn --> signInSuccessWithAuthResult');
        var user = authResult.user;
        var isNewUser = authResult.additionalUserInfo.isNewUser;
        
        if (isNewUser) {
          
          setHost({somedata: 'aString'})
            .then((result) => {
              // Read result of the Cloud Function.
              console.log('new user setHost result --->');
              console.log(result);
            })
            .catch((error) => {
              // Getting the Error details.
              var code = error.code;
              var message = error.message;
              var details = error.details;

            });
          // user.sendEmailVerification();
        } else {
          console.log('existing user!');
          
          /* 
            Not a new user. But this could an existing rver member 
            trying to also become a host. So check custom claims for isHost=true.
            If it's not there, set it. Otherwise, move along.
          */
         user.getIdTokenResult()
         .then((idTokenResult) => {
            // Confirm the user is an Admin.
            if (!!idTokenResult.claims.isHost) {
              // Show admin UI.
              console.log('isHost custom claim found');
            } else {
              console.log('NO isHost custom claim. So set it');
            }
         })
         .then(() => {
          setHost({somedata: 'do i need this??'});
         })
         .then((result) => {
          console.log('existing user, but new host setHost result --->');
          console.log(result);
          
          history.push(ROUTES.HOST + ROUTES.PROFILE);
        })
         .catch((error) => {
          console.log(error);
          var code = error.code;
          var message = error.message;
          var details = error.details;

          console.log('ERROR in setHost');
          console.log('code: ' + code);
          console.log('message: ' + message);
          console.log('details: ' + details);
         });

        }
        
        return false;
      },
      
    },  
    signInSuccessUrl: '/host/profile',
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,    
    ],
    // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    // Terms of service url/callback.
    tosUrl: ROUTES.TERMS,
    // Privacy policy url/callback.
    privacyPolicyUrl: function() {
      history.push(ROUTES.PRIVACY);
    }
  }
  
  
  return (
    <Grid container spacing={3} justify="center">
      <Grid item xs={12}>
        <Typography variant='h3' color="textPrimary" align="center" gutterBottom>Welcome back, Host person! Sign in.</Typography>
      </Grid> 
      <Grid item xs={12}>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
      </Grid> 
    </Grid>
  );
}

export default HostSignIn;