import React from 'react';
import { Grid, Container, Paper, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.primary.main,
    padding: '1rem',
    borderRadius: '5px'
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100px',
    padding: '2rem 0 1rem 0'
  },
  logo: {
    width: '100px',
    height: '90px'
  },
  footerLink: {
    textDecoration: 'none',
    color: 'white'
  },
  container: {
    paddingTop: '1rem',
    paddingLeft: 0,
    paddingRight: 0,
  }
}));


const Footer = () => {
  const classes = useStyles();
  // const theme = useTheme();
  
  return (
    <Container className={classes.container}>
      <Paper className={classes.paper} square={true}>
    <Grid container justify="space-around" >
      <Grid item xs={12} sm="auto" >
           <Typography >
             <Link to={ROUTES.RVER} className={classes.footerLink}>Members</Link>
           </Typography>
           <Typography>
              <Link to={ROUTES.HOST} className={classes.footerLink}>Hosts</Link>
           </Typography>
      </Grid>
      <Grid item  xs={12} sm="auto"  >
           <Typography>
           <Link to={ROUTES.CONTACT} className={classes.footerLink}>Contact</Link>
           </Typography>
           <Typography>
           <Link to={ROUTES.ABOUT} className={classes.footerLink}>About</Link>
           </Typography>
      </Grid>
      <Grid item  xs={12} sm="auto" >
           <Typography>
             <Link to={ROUTES.COMMANDMENTS} className={classes.footerLink}>The Holy Hosts Commandments</Link>
           </Typography>
           <Typography>
           <Link to={ROUTES.LEGAL} className={classes.footerLink}>Legal Stuff</Link>
           </Typography>
           
      </Grid> 
      <Grid item  xs={12} sm="auto" >
           <Typography>
           <Link to={ROUTES.PRIVACY} className={classes.footerLink}>Privacy</Link>
           </Typography>
           <Typography>
           <Link to={ROUTES.TERMS} className={classes.footerLink}>Terms</Link>
           </Typography>
      </Grid> 
      <Grid item xs={12} >
        <Container className={classes.logoContainer}>
          <img className={classes.logo} src="/images/HH_Logo_White.png" alt="logo" />
        </Container>
        
      </Grid> 
      
      <Grid item sm={12}>
        <Typography align="center" variant="body2" color="textSecondary"> 
           &#169; 2021, The Holy Hosts™
         </Typography>
      </Grid> 
      
      
      
 
    </Grid>
    </Paper>
    </Container>
  );
}

export default Footer;