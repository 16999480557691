import React, { useContext } from 'react';
import { FirebaseContext } from '../../Firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'; 
 
const SignIn = () => {
  const firebase = useContext(FirebaseContext);
  
  var uiConfig = {    
    signInSuccessUrl: '/host/profile',
    signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    // Terms of service url/callback.
    tosUrl: '<your-tos-url>',
    // Privacy policy url/callback.
    privacyPolicyUrl: function() {
    window.location.assign('<your-privacy-policy-url>');
    }
  };
  
  return (
    <div className="main-container">
      <h1>Log In</h1>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
    </div>
  );  
}


export default SignIn;

// import React from 'react';

// const SignIn = ({
//   doSignIn
// }) => {
//   return (
//     <div className="signin-form">
//       <h1>Sign In</h1>
//       <div className="signin-item">
//         <label htmlFor="email">Email</label>
//         <input type="text" id="email"></input>
//       </div>

//       <div className="signin-item">
//         <label htmlFor="password">Password</label>
//         <input type="password" id="password"></input>
//       </div>

//       <button onClick={doSignIn} >Sign in!</button>
//     </div>
//   );
// }

// export default SignIn;