import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../Firebase/UserContext';

const Confirmation = () => {
  let {user, loading, isSubscriber} = useContext(UserContext);
  
  useEffect(() => {
    user.getIdToken(true);
  }, []);

  return (
    <div>this is the subscription confirmation page</div>
  )
}

export default Confirmation;