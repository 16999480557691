import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  main: {
    height: '100vh',
    backgroundColor: '#ffffff',
    width: '100%'
  }
}));

const HostLoading = () => {
  const classes = useStyles();
  
  return (
    <div className={classes.main}>

    </div>
  );
}

export default HostLoading;