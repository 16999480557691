import React, { useContext } from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import './rver.css';

import { UserContext} from '../Firebase/UserContext';

import RVerLanding from './RVerLanding';
import RVerSubscribe from './RVerSubscribe';
import RVerSignUp from './RVerSignUp';
import RVerSignIn from './RVerSignIn';
import Search from './Search';
import RVerLoading from '../Loading/RVerLoading';
import Confirmation from './Confirmation';

const RVer = () => {
  let { path } = useRouteMatch();
  
  let {user, loading, isSubscriber} = useContext(UserContext);
  
  if (loading) {
    return (
      <RVerLoading />
    );
  }
  // if (user) {
  //   if (isSubscriber) {
  //     return (   
  //       <div className="main-container">
  //         <main>
  //           <Route path={`${path}${ROUTES.SEARCH}`}>
  //             <Search />
  //           </Route>
  //           <Redirect to={`${path}${ROUTES.SEARCH}`} />
  //         </main>
  //       </div>
  //     );
  //   } else {
  //       return (
  //         <div className="main-container">
  //           <main>
  //             <Route path={`${path}${ROUTES.SUBSCRIBE}`}>
  //               <RVerSubscribe />
  //             </Route>
  //             <Redirect to={`${path}${ROUTES.SUBSCRIBE}`} />
  //           </main>
  //         </div>
  //       );
  //     }
  // }  


  
  return (
    <div className="main-container">
      <main>
          <Switch>
            <Route path={`${path}${ROUTES.CONFIRMATION}`}>
              <Confirmation />
            </Route>
            <Route path={`${path}${ROUTES.SIGN_UP}`}>
              <RVerSignUp />
            </Route>
            <Route path={`${path}${ROUTES.SIGN_IN}`}>
              <RVerSignIn />
            </Route>
            <Route path={`${path}${ROUTES.SEARCH}`}>
              <Search />
            </Route>
            <Route path={`${path}${ROUTES.INDEX}`}>
              <RVerLanding />
            </Route>
        </Switch>    
      </main>
    </div>
  );
  
}

export default RVer;