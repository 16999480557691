export const HostFieldsRequired = {
  churchName: "Church name",
  signupName: "Your name",
  signupTitle: "Your title",
  signupPhone: "Your phone",
  
  address1: "Address 1",
  city: "City",
  state: "State",
  zipcode: "Zipcode",
  
  website: "Website",
  callPhone: "Call option phone",
  textPhone: "Text option phone",
  email: "Email",
  
  rvMaxLength: "Maximum allowed RV length",
  rvMaxWidth: "Maximum allowed RV width",
  rvMaxHeight: "Maximum allowed RV height"
};

export const HostFieldsOptional = {
  address2: "Address 2",
  
  pets: "Are pets allowed?",
  children: "Are children allowed?",
  electricity: "Is electricity available?",
  water: "Is water available?",

  maxStay: "Maximum length of stay",
  contactPerson: "Contact person",
  reservationLeadTime: "Reservation lead time",
  nightsAvailable: "Nights available",
  blackoutDates: "Dates not available",
  donationLink: "Donation link",
  altDonationInstructions: "Alternate donation instructions",
  quietHours: "Quiet hours",

  specialNotes: "What makes your location special?",
  minDonation: "Minimum recommended donation",
  otherServices: "Other fee-based services offered",
  volunteerOpportunities: "Volunteer opportunities?",
  guestRules: "Guest rules"
};
