import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Navigation from './Navigation';
import { Link, useHistory } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 200,
  },
  hide: {
    display: 'none',
  },
  fullList: {
    width: 'auto',
  },
  logo: {
    maxWidth: '120px',
  },
  drawerLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary
  }
}));


const Header = () => {
  const classes = useStyles();
  let [open, setOpen] = React.useState(false);
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const anchor = matches ? 'left' : 'bottom';
  
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(open);
  };

  const navLinks1 = [
    {
      label: 'Wanderers',
      route: ROUTES.RVER
    },
    {
      label: 'Hosts',
      route: ROUTES.HOST
    },
  ];
  const navLinks2 = [
    {
      label: 'The Holy Hosts 10 Commandments',
      route: ROUTES.COMMANDMENTS
    },
    {
      label: 'Legal Stuff',
      route: ROUTES.LEGAL
    },
    {
      label: 'Contact',
      route: ROUTES.CONTACT
    },
    {
      label: 'About Us',
      route: ROUTES.ABOUT
    },
  ];

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {navLinks1.map((item, index) => (
          <ListItem button key={item.label} onClick={()=> history.push(item.route)}>
            <ListItemText>{item.label}</ListItemText>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {navLinks2.map((item, index) => (
          <ListItem button key={item.label} onClick={()=> history.push(item.route)}>
            <ListItemText>{item.label}</ListItemText>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(anchor, true)}
            edge="start"
            className={classes.menuButton}
          >
            <MenuOpenIcon />
          </IconButton>
          
          <Link to={ROUTES.INDEX}><img src="/images/HH_Logo_CMYK_White_notext.png" alt="logo" className={classes.logo}/></Link>
          { matches ?
              <img src="/images/HH_Logo_CMYK_White_text.png" alt="logo" className={classes.logo} />
            :
            null
          }
          <Navigation />
          
        </Toolbar>
      </AppBar>
      <Drawer anchor={anchor} open={open} onClose={toggleDrawer(anchor, false)}>
        {list(anchor)}
      </Drawer>
    </div>
  );
}

export default Header;