export const INDEX = '/';
export const RVER = '/rver';
export const HOST = '/host';

export const PROFILE = '/profile';
export const SUBSCRIBE = '/subscribe';
export const SEARCH = '/search';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const LOGIN = '/login';
export const RESULTS = '/results';
export const DETAIL = '/detail';

export const ABOUT = '/about';
export const COMMANDMENTS = '/commandments';
export const LEGAL = '/legal';
export const CONTACT = '/contact';
export const VERIFY = '/verify';
export const PRIVACY = '/privacy';
export const TERMS = '/terms';

export const CONFIRMATION = '/confirmation';