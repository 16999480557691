import React from 'react';
import { Grid, Typography, Divider, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { formatPhone } from '../../../../util/format';

const useStyles = makeStyles((theme) => ({
  main: {
    padding: '2rem',
  },
  childrenEtc: {
    // borderRadius: '5px',
    [theme.breakpoints.down('xs')] : {
      margin: '1rem',
    }
  },
  image: {
    width: '100%', 
    // borderRadius: '5px', 
  },
  divider: {
    marginTop: '1rem',
    marginBottom: '1rem'
  }
  
}));

const SearchResultDetail = ({item}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
    <IconButton color="primary" aria-label="go back" onClick={() => history.goBack()}>
      <ArrowBackIcon />
    </IconButton>
    <Grid container spacing={3} className={classes.main}>
      
      <Grid item xs={12} >
        <img className={classes.image} src={item.churchPhoto} />
        <br />
        <br />
        <Typography variant="h4">
          {item.churchName}
        </Typography>
      </Grid> 

      <Grid item xs={12} sm={6} > 
        <Typography variant="body1">
        <strong>Address:</strong> {item.address1} 
        {
          item.address2 ? <br /> + item.address2 : null
        }
        &nbsp;{item.city}, {item.state}, {item.zipcode}
        </Typography>
        <Typography variant="body1">
          <strong>Contact Person:</strong> {item.contactPerson}
        </Typography>
        <Typography variant="body1">
          <strong>Phone:</strong> {formatPhone(item.phone)}
        </Typography>
        <Typography variant="body1">
          <strong>Email:</strong> {item.email}
        </Typography>
      </Grid>  

      
      {/* <Divider  className={classes.divider}/> */}
        
      <Grid item xs={12} sm={6}>  
        <Typography variant="body1" component="div">
        <strong>This Host can accomodate RVs up to:</strong>
          <ul>
            <li>{item.rvMaxHeight} feet high,</li>
            <li>{item.rvMaxLength} feet long,   </li>
            <li>{item.rvMaxWidth} feet wide</li>
          </ul>
          
        </Typography>
        {/* <Divider  className={classes.divider}/> */}
      </Grid>

      <Grid item xs={12} sm={6}>    
        <Typography variant="body1">
          <strong>Maximum Stay:</strong> {item.maxStay} {item.maxStay > 1 ? 'nights' : 'night'}
        </Typography>
        {
          item.minDonation > 0 &&
          <Typography variant="body1">
            <strong>Minimum suggested donation:</strong> ${item.minDonation}
          </Typography>
        }
        
      </Grid>

      <Grid item xs={12} sm={6} >  
        <div  className={classes.childrenEtc}>
          <Typography variant="body1" component="span">
            { item.children ? 'Children welcome' : 'No children, please'} -
            { item.pets ? ' Pets welcome' : ' No pets, please'}
          </Typography>
          <Typography variant="body1">
            { item.water ? 'Water available' : 'No water available'} -
            { item.electricity ? ' Electricity available' : ' No electricity available'}
          </Typography>
        </div>
      </Grid>

      
      <Grid item sm={12} >
        <Typography variant="body1">
          {
            item.volunteerOpportunities !== '' &&
            <>
              <Divider  className={classes.divider}/>
              <strong>Volunteer opportunities:</strong> {item.volunteerOpportunities}
              <Divider  className={classes.divider}/>
            </>
          }
        </Typography>
        
        
        <Typography variant="body1">
          { 
             item.specialNotes !== '' && 
             <>
              <strong>Special Notes:</strong> {item.specialNotes}
              <Divider  className={classes.divider}/>
            </>
          } 
        </Typography>
        
        
        <Typography variant="body1">
          { 
             item.otherServices !== '' && 
             <><strong>Other services: </strong>{item.otherServices}</>
          } 
        </Typography>
        </Grid>

    </Grid>
    </>
  );
}

export default SearchResultDetail;