import React, { useEffect, createRef } from 'react'

const GoogleMap = ({center, data}) => {
  const googleMapRef = createRef();
  
  useEffect(()=> {
    
    let googleMap = new window.google.maps.Map(googleMapRef.current, {
      zoom: 11,
      center: {
        lat: center.latitude, 
        lng: center.longitude
      }, 
      disableDefaultUI: true,
    });

    for (let i = 0; i < data.length; i++) {
      let d = data[i];
      let m = new window.google.maps.Marker({
          position: { lat: d.geopoint.latitude, lng: d.geopoint.longitude },
          map: googleMap,
          title: data.name
        });

      let iw = new window.google.maps.InfoWindow({content: '<div>' + d.churchName +  '</div>'});
    
      m.addListener('click', () =>{
        iw.open(googleMap, m);
      });
  
    }

  }, [googleMapRef, data, center]);

    return (
      <div
        id="google-map"
        ref={googleMapRef}
        style={{ width: '100%', height: '100%' }}
      />
    )
}

export default GoogleMap; 