import React from 'react';
import { Grid, Typography, Divider, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { formatPhone } from '../../../util/format';

const useStyles = makeStyles((theme) => ({
  main: {
    padding: '2rem',
  },
  childrenEtc: {
    borderRadius: '5px',
    [theme.breakpoints.down('xs')] : {
      margin: '1rem',
    }
  },
  image: {
    width: '100%', 
    borderRadius: '5px', 
  },
  divider: {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  grouping: {
    padding: '2rem'
  }
  
}));

const HostDataView = ({hostData, formatPhone}) => {
  const classes = useStyles();
  const history = useHistory();
 
  return (
    <>
    
    <Grid container spacing={3} className={classes.main}>
      <Grid item xs={12} >
        <Typography variant="h5">
          Your information for our files:
        </Typography>
        <Paper className={classes.grouping}>
          <p><strong>Your name:</strong> {hostData.signupName}</p>
          <p><strong>Your title:</strong> {hostData.signupTitle}</p>
          <p><strong>Your phone:</strong> {formatPhone(hostData.signupPhone)}</p>
        </Paper>
      </Grid>
      <Grid item xs={12} >
        <br />
        <Typography variant="h5">
          Here's how your listing will appear to prospective guests:
        </Typography>
      </Grid>
      
      <Grid item xs={12} >
        <Grid container spacing={3} className={classes.main}>
          {
            hostData.hostImage1 ?
              <Grid item xs={12} sm={4}>
                <img className={classes.image} src={hostData.hostImage1} />
              </Grid>
            : null
            
          }
          {
            hostData.hostImage2 ?
              <Grid item xs={12} sm={4}>
                <img className={classes.image} src={hostData.hostImage2} />
              </Grid>
              : null      
          }
          {
            hostData.hostImage3 ?
              <Grid item xs={12} sm={4}>
                <img className={classes.image} src={hostData.hostImage3} />
              </Grid>
              : null      
          }
         
        </Grid>

        <Typography variant="h4">
          {hostData.churchName}
        </Typography>
      </Grid> 

      <Grid item xs={12} sm={6} > 
        <Typography variant="h5">
        {hostData.address1} 
        <br />
        {
          hostData.address2 ? 
          <>
          {hostData.address2}<br />

          </> : null
        }
        {hostData.city}, {hostData.state}, {hostData.zipcode}
        </Typography>
        
        <br />
        { hostData.pets ? 
          <Typography variant="body1" component="div">
            Pets welcome
          </Typography>
          : null
        }
        { hostData.children ? 
          <Typography variant="body1" component="div" >
            Children welcome
          </Typography>
          : null
        } 
          
        <br />
        <Typography variant="body1" component="div">
          <strong>We can accomodate RVs up to:</strong><br />
          {hostData.rvMaxLength} feet long, {hostData.rvMaxHeight} feet high, and {hostData.rvMaxWidth} feet wide.
        </Typography>

        <br />
        { hostData.water ? 
          <Typography variant="body1" component="div" >
            Water available
          </Typography>
          : null
        } 
         { hostData.electricity ? 
          <Typography variant="body1" component="div" >
            Electricity available
          </Typography>
          : null
        } 
        <br />
        <Typography variant="body1">
          { 
             hostData.specialNotes !== '' && 
             <>
              <strong>What makes our site special?</strong> {hostData.specialNotes}
            </>
          } 
        </Typography>

        <Typography variant="body1">
          {
            hostData.donationLink  || hostData.altDonationInstructions ?
              <>
              {/* <Divider  className={classes.divider}/> */}
                  {
                hostData.donationLink ?
                <>
                  <strong>To make a donation, please visit:</strong> <a href={hostData.donationLink} target="_blank">{hostData.donationLink}</a> 
                </>
                : null
              }
              <br />
              {
                hostData.altDonationInstructions ?
                <>
                  <strong>Donation Instructions:</strong> {hostData.altDonationInstructions}
                </>
                : null
              }
              </>
            : null  
          }
        </Typography>

        <br />
        <Typography variant="body1">
          {
            hostData.volunteerOpportunities !== '' &&
            <>
              <strong>Volunteer opportunities:</strong> {hostData.volunteerOpportunities}
            </>
          }
        </Typography>
        
        <Typography variant="body1">
          { 
             hostData.otherServices !== '' && 
             <><strong>Other services: </strong>{hostData.otherServices}</>
          } 
        </Typography>
        <br />
           
        <Typography variant="body1">
          { 
             hostData.guestRules !== '' ? 
             <>
              <strong>Some rules for our guests: </strong>{hostData.guestRules}
            </>
            : 
            <>
            <strong>Please ask us any questions you might have about rules and regulations.</strong>
            </>
          } 
        </Typography>
        <br />
        {
          hostData.quietHours ? 
            <Typography variant="body1">
              Please observe our quiet hours: {hostData.quietHours} 
            </Typography>
            :
            null
        }
      </Grid>  
     
      <Grid item xs={12} sm={6}> 
        <Paper className={classes.grouping} >
          <Typography variant="h5" gutterBottom>
            Please contact us to make your reservation.
          </Typography>
          <Typography variant="body1">
            <strong>Contact Person:</strong> {hostData.contactPerson}
          </Typography>
        {
          hostData.callPhone ?
            <Typography variant="body1">
              <strong>Phone:</strong> {formatPhone(hostData.callPhone)}
            </Typography>
          : null
        }
        
        {
          hostData.textPhone ?
            <Typography variant="body1">
              <strong>Text:</strong> {formatPhone(hostData.textPhone)}
            </Typography>
          : null        
        }
        
        {
          hostData.email ?
            <>
            <Typography variant="body1">
              <strong>Email:</strong> {hostData.email}
            </Typography>
            </>
          : null
        }
        <br />
          {
            hostData.reservationLeadTime ?
            <Typography variant="body1">
              Please make reservations at least {hostData.reservationLeadTime} in advance of your stay.
            </Typography>
            : null
          }
        <br />
        <Typography variant="body1">
            <strong>Visit us online at:</strong> <a href="{hostData.website}" target="_blank">{hostData.website}</a> 
          </Typography>
          
          {
            hostData.maxStay && hostData.maxStay > 0 ?
            <Typography variant="body1">
              <strong>Maximum Stay:</strong> {hostData.maxStay} {hostData.maxStay > 1 ? 'nights' : 'night'}
            </Typography>
            : null

          }

          {
            hostData.minDonation > 0 &&
            <Typography variant="body1">
              <strong>Minimum suggested donation:</strong> ${hostData.minDonation}
            </Typography>
          }
          
          <br />
          {
            hostData.nightsAvailable ?
            <Typography variant="body1">
              Our location is typically available on these nights: <br />{hostData.nightsAvailable}
            </Typography>
            : null
          }
          <br />
          {
            hostData.blackoutDates ?
            <Typography variant="body1">
              Our location is typically NOT available on these nights: <br />{hostData.blackoutDates}
            </Typography>
            : null
          }
        </Paper>   
        
      </Grid>

      
      
      <Grid item sm={12} >
        
        
        </Grid>

    </Grid>
    </>
  );
}

export default HostDataView;

