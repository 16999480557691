import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { UserContext } from '../Firebase/UserContext'
import UserMenu from './UserMenu';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
}));

const RVerNavigation = ()=> {
  const classes = useStyles();
  let history = useHistory();
  let {user} = useContext(UserContext);

  return (
    <>
    <Typography variant="h6" className={classes.title}>
      </Typography>
    {user ?
        <UserMenu/>
      :
      <>
        <Typography variant="h6" className={classes.title}></Typography>
        {/* <Button disabled color="inherit" onClick={() => {history.push(ROUTES.RVER + ROUTES.SIGN_IN)}}>Log In</Button>
        <Button disabled co lor="inherit" onClick={() => {history.push(ROUTES.RVER + ROUTES.SIGN_UP)}}>Become a Member</Button> */}
      </>
    }
    </>
  );
}

export default RVerNavigation;