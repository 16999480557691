import React from 'react';
import ScrollToTop from '../../util/ScrollToTop';

import Header from '../Header';
import Main from '../Main';
import Footer from '../Footer';

const App = () => {
  return (
    <>
        <ScrollToTop />
        <Header/>
        <div style={{position: 'relative', height: '100px'}}></div>
            <Main />
        <div style={{position: 'relative', height: '10px'}}></div>
        <Footer/>
    </>
    
  );
}
 
export default App;