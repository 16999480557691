import React, {useContext} from 'react';
import { FirebaseContext } from '../../Firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'; 
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom'; 
import * as ROUTES from '../../../constants/routes';

const HostSignUp = () => {
  const history = useHistory();
  const firebase = useContext(FirebaseContext);
  const func = firebase.functions();
  const setHost = func.httpsCallable('setHost');

  var uiConfig = {    
    callbacks: {
      signInSuccessWithAuthResult: function(authResult, redirectUrl) {
        var user = authResult.user;
        // var credential = authResult.credential;
        var isNewUser = authResult.additionalUserInfo.isNewUser;
        // var providerId = authResult.additionalUserInfo.providerId;
        // var operationType = authResult.operationType;

        // var actionCodeSettings = {
        //   url: continueUrl
        // }

        // Do something with the returned AuthResult.
        // Return type determines whether we continue the redirect
        // automatically or whether we leave that to developer to handle.

        // if isNewUser == true, then current action is signup
        // if isNewUser == false, then the current action is signin 
        if (isNewUser) {
          
          setHost({somedata: 'really... do i need this??'})
            .then((result) => {
              // Read result of the Cloud Function.
              console.log('setHost result --->');
              console.log(result);
            })
            .then(() => {
              user.getIdToken(true);
            })
            .then(() => {
              history.push(ROUTES.HOST + ROUTES.PROFILE);
            })
            .catch((error) => {
              // Getting the Error details.
              var code = error.code;
              var message = error.message;
              var details = error.details;

              console.log('ERROR in setHost');
              console.log('code: ' + code);
              console.log('message: ' + message);
              console.log('details: ' + details);
            });
          // user.sendEmailVerification();
        }
        else {
          console.log('existing user');
        
           /* 
            Not a new user. But this could an existing rver member 
            trying to also become a host. So check custom claims for isHost=true.
            If it's not there, set it. Otherwise, move along.
          */
         user.getIdTokenResult()
         .then((idTokenResult) => {
            let claimFound = false;
            // Confirm the user is an Admin.
            if (!!idTokenResult.claims.isHost) {
              // Show admin UI.
              console.log('isHost custom claim found');
              claimFound = true;
            } else {
              console.log('NO isHost custom claim. So set it');
            }
            return claimFound;
         })
         .then((claimFound) => {
           
          if (!claimFound) {
            console.log('call setHost on existing user');
            setHost({somedata: 'do i need this??'});
          }
          
         })
         .then((result) => {
          console.log('setHost result --->');
          console.log(result);
          //TODO: Don't set the window location. Return a Redirect instead. 
          // window.location = '/host/profile';
        })
         .catch((error) => {
          console.log(error);
          var code = error.code;
          var message = error.message;
          var details = error.details;

          console.log('ERROR in setHost');
          console.log('code: ' + code);
          console.log('message: ' + message);
          console.log('details: ' + details);
         });


        }
        
        return false;
      },
      // signInFailure: function(error) {
      //   console.log('signInFailure');
      //   // Some unrecoverable error occurred during sign-in.
      //   // Return a promise when error handling is completed and FirebaseUI
      //   // will reset, clearing any UI. This commonly occurs for error code
      //   // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
      //   // occurs. Check below for more details on this.
      //   return handleUIError(error);
      // },
      // uiShown: function() {
      //   console.log('uiShown');
      //   // The widget is rendered.
      //   // Hide the loader.
      //   // document.getElementById('loader').style.display = 'none';
      // }
    },  
    signInSuccessUrl: '/host/profile',
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,    
    ],
    // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    // Terms of service url/callback.
    tosUrl: ROUTES.TERMS,
    // Privacy policy url/callback.
    privacyPolicyUrl: function() {
      history.push(ROUTES.PRIVACY);
    }
  }
  
  
  return (
    <Grid container spacing={3} justify="center">
      <Grid item xs={12}>
        <Typography variant='h3' color="textPrimary" align="center" gutterBottom>Become a Host</Typography>
      </Grid> 
      <Grid item xs={12}>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
      </Grid> 
    </Grid>
  );
}

export default HostSignUp;